import React, {Component} from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Segment, Divider, Loader, Header, Icon, Message, Button } from 'semantic-ui-react'
import ParkingInvoice from '../../components/transactionHistory/ParkingInvoice'
import { onFetchTransactionHistory, onFetchMoreTransactions } from '../../modules/transactionHistory/fullTransactionHistory'
import { Link } from 'react-router-dom'

class TransactionHistory extends Component {

    componentWillMount() {
         this.props.onFetchTransactionHistory();
    }

    handleFetchMoreTransactions(e) {
        e.preventDefault();
        const { currentPage, onFetchMoreTransactions } = this.props;
        onFetchMoreTransactions(currentPage + 1);
    }

    render() {
        const { transactions, hasMorePages, fetching, fetchError, fetchingMoreTransactions, fetchMoreTransactionsError, onFetchTransactionHistory } = this.props;

        var component = null;

        if (fetching) {
            component = <Loading {... this.props}/>;
        } else if (fetchError) {
            component = <FetchError onTryAgain={onFetchTransactionHistory}/>;
        } else {
            if(!transactions || transactions.length === 0) {
                component = <NoTransactions/>
            } else {
                component = (
                    <div>
                        {transactions.map((transaction, i) =>
                            <ParkingInvoice key={transaction.id} transaction={transaction}/>
                        )}
                        {hasMorePages ? <Segment loading={fetchingMoreTransactions} basic textAlign='center'>
                            {fetchMoreTransactionsError ? <Message negative>There was a problem loading more parking invoices. Please try again</Message> : null}
                            <p><a href="" onClick={this.handleFetchMoreTransactions.bind(this)}>View more parking invoices</a></p>
                        </Segment>: null}
                    </div>
                );
            }
        }
        return (
            <div>
                <Segment basic textAlign='center'>
                    Please note, payments for parking will normally be processed within 10 days of your parking session and invoices may not appear here immediately
                </Segment>
                <Divider/>
                {component}
                <Divider/>
                <Segment basic textAlign='center'>
                    <p>Back to <Link to="/dashboard">your dashboard</Link></p>
                </Segment>
            </div>
        );
    }
}

const NoTransactions = () => {
    return (
        <Segment disabled secondary textAlign='center'>
            <Header as='h4' icon>
                <Icon name='history' color='grey' />
                    You have no previous transactions
            </Header>
        </Segment>
    );
}

const FetchError = (props) => {
    return (
        <Segment disabled secondary textAlign='center'>
            <Header as='h4' icon>
                <Icon name='frown' />
                    Failed to load parking invoice history
                <Header.Subheader>
                    Please&nbsp;<a href='#' onClick={(e) => {e.preventDefault(); props.onTryAgain(); }}>click here</a>&nbsp;to try again.
                </Header.Subheader>
            </Header>
        </Segment>
    );
}

const Loading = (props) => {
  return (
    <Loader active inline='centered' size='small'>Loading transactions</Loader>
  )
}

const mapStateToProps = state => ({
    transactions: state.fullTransactionHistory.transactions,
    hasMorePages: state.fullTransactionHistory.hasMorePages,
    currentPage: state.fullTransactionHistory.currentPage,
    fetching: state.fullTransactionHistory.fetching,
    fetchError: state.fullTransactionHistory.fetchError,
    fetchingMoreTransactions: state.fullTransactionHistory.fetchingMoreTransactions,
    fetchMoreTransactionsError: state.fullTransactionHistory.fetchMoreTransactionsError
})

const mapDispatchToProps = dispatch => bindActionCreators({
    onFetchTransactionHistory,
    onFetchMoreTransactions
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransactionHistory)

