import hozahConfig from '../../config/HozahConfig'
import { CALL_API, getJSON } from 'redux-api-middleware';
import { push } from 'react-router-redux'

export const START_NEW_LOOKUP = 'addVehicle/START_NEW_LOOKUP'
export const LOOKUP_VEHICLE_DETAILS = 'addVehicle/LOOKUP_VEHICLE_DETAILS'
export const VEHICLE_DETAILS_FOUND = 'addVehicle/VEHICLE_DETAILS_FOUND'
export const FAILED_TO_LOOKUP_VEHICLE_DETAILS = 'addVehicle/FAILED_TO_LOOKUP_VEHICLE_DETAILS'
export const ADD_VEHICLE = 'addVehicle/ADD_VEHICLE'
export const ADDED_VEHICLE = 'addVehicle/ADDED_VEHICLE'
export const FAILED_TO_ADD_VEHICLE = 'addVehicle/FAILED_TO_ADD_VEHICLE'

export const onStartNewLookup = () => {
    return dispatch => {
      dispatch({
        type: START_NEW_LOOKUP
      });
    }
}

let url = ""

export const onAddNewVehicle = (vrm) => {
  
  if (window.location.href.indexOf("new") != -1){
    url = "/new/dashboard"
  } else {
    url = "/dashboard"
  }
  
  return dispatch => {
        dispatch({
            [CALL_API]: {
                endpoint: `/vehicles`,
                method: 'POST',
                body: JSON.stringify({ vrm: vrm }),
                types: [
                  ADD_VEHICLE, 
                  {
                    type: ADDED_VEHICLE,
                    payload: (action, state, res) => {
                      dispatch(push(url))
                      return getJSON(res).then(json => {
                        return json;
                      });
                    }
                  },
                  {
                    type: FAILED_TO_ADD_VEHICLE,
                    meta: (action, state, res) => {
                      if (res.status == 409) {
                        return {
                          alreadyRegistered: true
                        };
                      } else {
                        return {};
                      }
                    }
                  }]
            }
        });

    }
}

export const onLookupVehicleDetails = (vrm) => {
    return dispatch => {
        dispatch({
            [CALL_API]: {
                endpoint: `/vehicle-details/${vrm}`,
                method: 'GET',
                types: [
                  {
                    type: LOOKUP_VEHICLE_DETAILS,
                    payload: { vrm: vrm }
                  }, 
                  VEHICLE_DETAILS_FOUND,
                  {
                    type: FAILED_TO_LOOKUP_VEHICLE_DETAILS,
                    meta: (action, state, res) => {
                      if (res.status == 404) {
                        return {
                          notFound: true
                        };
                      } else {
                        return {};
                      }
                    }
                  }]
            }
        });
    }
}

const initialState = {
  vrm: null,
  vehicleDetails: null,
  lookingUpVehicleDetails: false,
  addingVehicle: false,
  lookupVehicleDetailsError: null,
  addVehicleError: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case START_NEW_LOOKUP:
      return {
        ... state,
        vrm: null,
        vehicleDetails: null,
        lookingUpVehicleDetails: false,
        addingVehicle: false,
        lookupVehicleDetailsError: null,
        addVehicleError: null
      };
    case LOOKUP_VEHICLE_DETAILS:
      return {
        ...state,
        vrm: action.payload.vrm,
        vehicleDetails: null,
        lookingUpVehicleDetails: true,
        lookupVehicleDetailsError: null
      };
    case VEHICLE_DETAILS_FOUND:
      return {
        ...state,
        vehicleDetails: action.payload,
        lookingUpVehicleDetails: false,
        lookupVehicleDetailsError: null
      };
    case ADD_VEHICLE:
      return {
        ...state,
        addingVehicle: true
      };
    case ADDED_VEHICLE:
      return {
        ...state,
        addingVehicle: false,
        addVehicleError: null
      };
    case FAILED_TO_LOOKUP_VEHICLE_DETAILS:
      return {
        ...state,
        vehicleDetails: null,
        lookingUpVehicleDetails: false,
        lookupVehicleDetailsError: action.meta.notFound ? null : action.payload.statusText
      };
    case FAILED_TO_ADD_VEHICLE:
      return {
        ...state,
        addingVehicle: false,
        addVehicleError: action.meta.alreadyRegistered ? "A vehicle with this number plate is already registered. Please get in touch if you own this vehicle and we'll transfer it to you from the previous owner" : action.payload.statusText
      };
    default:
      return state;
  }
}