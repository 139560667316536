import React, {Component} from 'react'
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Segment, Icon, Label, Button, Grid, Message, Header } from 'semantic-ui-react';

const clickable ={ 'display': 'block' };

const NoVehicles = (props) => {
    return (
        <Segment disabled secondary textAlign='center'>
            <Link to='/dashboard/vehicles/add' style={clickable}>
                <Header as='h4' icon>
                    <Icon name='car' />
                        You have no registered vehicles
                    <Header.Subheader>
                        Click here to add one
                    </Header.Subheader>
                </Header>
            </Link>
        </Segment>
    );
};

export default NoVehicles;