import React, {Component} from 'react'
import PropTypes from 'prop-types';
import { Segment, Icon, Label, Button, Grid, Modal, Header, Dimmer, Loader } from 'semantic-ui-react';

const noBorderStyle ={ 'borderStyle': 'none' };

const deleteVehicleButtonStyle = { 
    'borderStyle': 'none',
    'backgroundColor': 'white'
};

const vrmStyle = { 'color': 'black' }

const clickable = { 
    'display': 'block',
    'color': 'white'
};

class DeleteVehicleModal extends Component {
    
  state = { open: false }

  handleShow = () => this.setState({ open: true })
  handleNo = () => this.setState({ open: false })
  handleYes = () => {
      this.setState({ open: false });
      this.props.onDelete(this.props.id);
  }

  render() {
    const { open } = this.state;
    const { vrm } = this.props;
    return (
        <div> 
            <Icon style={deleteVehicleButtonStyle} color='red' name='cancel' circular link onClick={this.handleShow}/>
            <Modal open={open} basic size='small'>
                <Header icon='archive' content={vrm} style={noBorderStyle}/>
                <Modal.Content >
                    <h4> Are you sure you want to remove this vehicle? <br/> The vehicle will no longer be covered by Hozah.</h4>
                </Modal.Content>
                <Modal.Actions style={noBorderStyle}>
                    <Button.Group widths={2}>
                    <Button color='grey' onClick={this.handleNo}>No</Button>
                    <Button color='green' className="oldButton" onClick={this.handleYes}>Yes</Button>
                    </Button.Group>
                </Modal.Actions>
            </Modal>
        </div>
    );
  }
}

const VehicleLink = (props) => {
    const { id, vrm, deleting, onDelete } = props;
    return ([
        <Segment key={0}style={noBorderStyle} secondary inverted attached color='blue' textAlign='center'>
                {deleting ? <Dimmer inverted active><Loader size='small'>Deleting</Loader></Dimmer> : null}
                <Icon size='huge' name='car'/>
        </Segment>,
        <Segment disabled={deleting} key={1} style={noBorderStyle} inverted attached color='blue' attached='bottom' textAlign='left'>
            <Grid columns={2} verticalAlign='middle'>
                <Grid.Column width={10} textAlign='left'><Label color='yellow' size='large'><span style={vrmStyle}>{vrm}</span></Label></Grid.Column>
                <Grid.Column width={6} textAlign='right'>{!deleting ? <DeleteVehicleModal id={id} vrm={vrm} onDelete={onDelete}/> : null}</Grid.Column>
            </Grid>
        </Segment>
    ]);
};

VehicleLink.propTypes = {
  id: PropTypes.string.isRequired,
  vrm: PropTypes.string.isRequired,
  deleting: PropTypes.bool,
  onDelete: PropTypes.func.isRequired
};

export default VehicleLink