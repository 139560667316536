import React, {Component} from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {Divider, Loader, Message, Segment} from 'semantic-ui-react'
import Vehicles from '../../components/dashboard/vehicles/Vehicles';
import ExpandableSection from '../../components/dashboard/ExpandableSection';
import {
    onCollapseSubsection,
    onDeleteVehicle,
    onDismissGlobalError,
    onExpandSubsection,
    onFetchDashboardHome,
    PAYMENT_DETAILS_SECTION,
    PERMITS_SECTION,
    TRANSACTION_HISTORY_SECTION
} from '../../modules/dashboard';
import TransactionHistorySummary from './TransactionHistorySummary';
import Permits from './Permits';
import PaymentDetails from './PaymentDetails';
import DriverSettingsWizard from './DriverSettingsWizard';

class Dashboard extends Component {

    constructor(props) {
        super(props);
    }

    componentWillMount() {
      this.props.onFetchDashboardHome()
    }

    render() {
        const { initialising, initialised, initialisationError } = this.props;
        if (initialising) {
            return (<Loading {... this.props}/>);
        } else {
            return (<UserDashboard {... this.props} />);
        }
    }
 }


class UserDashboard extends Component {

    handleExpandSubsection = (name) => {
        this.props.onExpandSubsection(name);
    }

    handleCollapseSubsection = (name) => {
        this.props.onCollapseSubsection(name);
    }

    isExpanded = (name) => {
      return this.props.expandedSubsection === name;
    }

    handleDeleteVehicle = (vehicleId) => {
      this.props.onDeleteVehicle(vehicleId);
    }

    handleDismissGlobalError = () => {
      this.props.onDismissGlobalError();
    }

    render() {
      const { expandedSubsection, activeParkingSessions, vehicles, vehicleIdBeingDeleted, dashboardGlobalError, hasPaymentCard } = this.props;
        return (
          <DriverSettingsWizard>
            <div className='ui main text container'>
              {dashboardGlobalError ? <Message negative onDismiss={this.handleDismissGlobalError}>{dashboardGlobalError}</Message> : null}
              <Vehicles vehicles={vehicles} deleting={vehicleIdBeingDeleted} onDelete={this.handleDeleteVehicle}/>
              <Divider />
              <ExpandableSection name={TRANSACTION_HISTORY_SECTION} expanded={this.isExpanded(TRANSACTION_HISTORY_SECTION)} heading='Parking invoices' onExpand={this.handleExpandSubsection} onCollapse={this.handleCollapseSubsection}>
                  <Segment basic textAlign='center'>
                      Please note, payments for parking will normally be processed within 10 days of your parking session and invoices may not appear here immediately
                  </Segment>
                  <TransactionHistorySummary/>
              </ExpandableSection>
              <ExpandableSection name={PAYMENT_DETAILS_SECTION} expanded={this.isExpanded(PAYMENT_DETAILS_SECTION)} heading='Payment details' onExpand={this.handleExpandSubsection} onCollapse={this.handleCollapseSubsection}>
                  <Divider/>
                  <PaymentDetails/>
              </ExpandableSection>
              <ExpandableSection name={PERMITS_SECTION} expanded={this.isExpanded(PERMITS_SECTION)} heading='Permits' onExpand={this.handleExpandSubsection} onCollapse={this.handleCollapseSubsection}>
                  <Divider/>
                  <Permits/>
              </ExpandableSection>

            </div>
          </DriverSettingsWizard>
        );
    }
}

const Loading = (props) => {
  return (
    <Loader active inline='centered' size='large'>Loading dashboard</Loader>
  )
}

const mapStateToProps = state => ({
  initialised: state.dashboard.initialised,
  initialising: state.dashboard.initialising,
  initialisationError: state.dashboard.initialisationError,
  activeParkingSessions: state.dashboard.activeParkingSessions,
  vehicles: state.dashboard.vehicles,
  expandedSubsection: state.dashboard.expandedSubsection,
  vehicleIdBeingDeleted: state.dashboard.vehicleIdBeingDeleted,
  dashboardGlobalError: state.dashboard.dashboardGlobalError,
  hasPaymentCard: state.dashboard.hasPaymentCard
})

const mapDispatchToProps = dispatch => bindActionCreators({
  onFetchDashboardHome,
  onExpandSubsection,
  onCollapseSubsection,
  onDeleteVehicle,
  onDismissGlobalError
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard)
