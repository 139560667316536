import hozahConfig from '../config/HozahConfig'
import { CALL_API } from 'redux-api-middleware';
import { ADDED_VEHICLE } from './vehicles/addVehicle'
import { CREATED_CARD } from './payment/payment'

export const FETCH_DASHBOARD_HOME = 'dashboard/FETCH_DASHBOARD_HOME'
export const FETCHED_DASHBOARD_HOME = 'dashboard/FETCHED_DASHBOARD_HOME'
export const FAILED_TO_FETCH_DASHBOARD_HOME = 'dashboard/FAILED_TO_FETCH_DASHBOARD_HOME'
export const EXPAND_SUBSECTION = 'dashboard/EXPAND_SUBSECTION'
export const COLLAPSE_SUBSECTION = 'dashboard/COLLAPSE_SUBSECTION'
export const DELETE_VEHICLE = 'dashboard/DELETE_VEHICLE'
export const VEHICLE_DELETED = 'dashboard/DELETED_VEHICLE'
export const FAILED_TO_DELETE_VEHICLE = 'dashboard/FAILED_TO_DELETE_VEHICLE'
export const DISMISS_GLOBAL_ERROR = 'dashboard/DISMISS_GLOBAL_ERROR'

// Subsection names on dashboard
export const TRANSACTION_HISTORY_SECTION = 'dashboard/TRANSACTION_HISTORY_SECTION'
export const ACCOUNT_SETTINGS_SECTION = 'dashboard/ACCOUNT_SETTINGS_SECTION'
export const PAYMENT_DETAILS_SECTION = 'dashboard/PAYMENT_DETAILS_SECTION'
export const PERSONAL_DETAILS_SECTION = 'dashboard/PERSONAL_DETAILS_SECTION'
export const CONTACT_PREFERENCES_SECTION = 'dashboard/CONTACT_PREFERENCES_SECTION'
export const PERMITS_SECTION = 'dashboard/PERMITS'

export const onFetchDashboardHome = () => {
    return dispatch => {
        dispatch({
            [CALL_API]: {
                endpoint: '/dashboard',
                method: 'GET',
                types: [FETCH_DASHBOARD_HOME, FETCHED_DASHBOARD_HOME, FAILED_TO_FETCH_DASHBOARD_HOME]
            }
        });
    }
}

export const onExpandSubsection = (section) => {
    return dispatch => {
        dispatch({
            type: EXPAND_SUBSECTION,
            section: section
        });
    }
}

export const onCollapseSubsection = (section) => {
    return dispatch => {
        dispatch({
            type: COLLAPSE_SUBSECTION,
            section: section
        });
    }
}

export const onDismissGlobalError = () => {
    return dispatch => {
        dispatch({
            type: DISMISS_GLOBAL_ERROR
        });
    }
}

export const onDeleteVehicle = (vehicleId) => {
    return dispatch => {
        dispatch({
            [CALL_API]: {
                endpoint: `/vehicles/${vehicleId}`,
                method: 'DELETE',
                types: [
                  {
                    type: DELETE_VEHICLE,
                    payload: {
                      id: vehicleId
                    }
                  }, 
                  {
                    type: VEHICLE_DELETED,
                    payload: {
                      id: vehicleId
                    }
                  }, FAILED_TO_DELETE_VEHICLE]
            }
        });
    }
}

const initialState = {
  initialised: false,
  initialising: false,
  initialisationError: null,
  activeParkingSessions: [],
  vehicles: [],
  hasPaymentCard: false,
  expandedSubsection: null,
  vehicleIdBeingDeleted: null,
  dashboardGlobalError: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ADDED_VEHICLE :
      return {
        ...state,
        vehicles: [...state.vehicles, action.payload]
      }
    case CREATED_CARD:
      return {
        ...state,
        hasPaymentCard: true
      }
    case FETCH_DASHBOARD_HOME:
      return {
        ...state,
        initialised: false,
        initialising: true,
        initialisationError: false,
        dashboardGlobalError: null
      };
    case FETCHED_DASHBOARD_HOME:
      return {
        ...state,
        initialised: true,
        initialising: false,
        activeParkingSessions: action.payload.activeParkingSessions ? action.payload.activeParkingSessions : [],
        vehicles: action.payload.vehicles ? action.payload.vehicles : [],
        hasPaymentCard: action.payload.hasPaymentCard ? action.payload.hasPaymentCard : false,
        initialisationError: false,
        dashboardGlobalError: null
      };
    case FAILED_TO_FETCH_DASHBOARD_HOME:
      return {
        ...state,
        initialised: false,
        initialising: false,
        initialisationError: action.error,
        dashboardGlobalError: null
      };
    case EXPAND_SUBSECTION:
      return {
        ...state,
        expandedSubsection: action.section,
        dashboardGlobalError: null
      };
    case COLLAPSE_SUBSECTION:
      if(state.expandedSubsection === action.section) {
        return {
          ...state,
          expandedSubsection: null,
          dashboardGlobalError: null
        };
      } else {
        return {
          ...state,
          dashboardGlobalError: null
        }
      }
    case DELETE_VEHICLE:
      return {
        ...state,
        vehicleIdBeingDeleted: action.payload.id,
        dashboardGlobalError: null
      };
    case VEHICLE_DELETED:
      return {
        ...state,
        vehicles: state.vehicles.filter(vehicle => vehicle.id != action.payload.id),
        vehicleIdBeingDeleted: null,
        dashboardGlobalError: null
      };
    case FAILED_TO_DELETE_VEHICLE:
      return {
        ...state,
        vehicleIdBeingDeleted: null,
        dashboardGlobalError: "There was a problem deleting your vehicle. Please try again"
      };
    case DISMISS_GLOBAL_ERROR:
      return {
        ...state,
        dashboardGlobalError: null
      }
    default:
      return state;
  }
}