import React, {Component} from 'react'
import { Segment, Button, Divider, Message, Grid, Container } from 'semantic-ui-react'
import LookupVehicle from '../../components/addVehicle/LookupVehicle'
import ConfirmVehicle from '../../components/addVehicle/ConfirmVehicle'
import VehicleDetails from '../../components/addVehicle/VehicleDetails'
import { signup } from '../../modules/auth'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { onLookupVehicleDetails, onStartNewLookup, onAddNewVehicle } from '../../modules/vehicles/addVehicle'

class AddVehicle extends Component {

    doLookupVehicle = (vrm) => {
      this.props.onLookupVehicleDetails(vrm);
    }

    doConfirmVehicle = () => {
      const {onAddNewVehicle, vrm} = this.props;
      onAddNewVehicle(vrm)
    }

    doResetLookup = () => {
      this.props.onStartNewLookup();
    }

    componentWillMount() {
      this.props.onStartNewLookup();
    }

    render() {
        const { vrm, vehicleDetails, lookingUpVehicleDetails, addingVehicle, lookupVehicleDetailsError, addVehicleError, hideBackLink } = this.props;
        const pristine = !vrm;
        const vehicleDetailsNotFound = vrm && !vehicleDetails && !lookupVehicleDetailsError;
        const Confirm = (props) => vehicleDetails ? <ConfirmVehicle {...props}/> : null;

        return (
          <div>
            <div>
              <Grid columns={1} stackable textAlign='left'>
              <Grid.Column width={12}>

              <label>Registration number*</label>
              <div className="field" >
              <LookupVehicle onLookup={this.doLookupVehicle} fetching={lookingUpVehicleDetails} disabled={!pristine && vehicleDetails ? true: false}/>
              <p className="additional-help-text"> Enter your registration number to help us find your vehicle </p>
              </div>
              {!pristine && !lookingUpVehicleDetails ? [
                <VehicleDetails key={1} data={vehicleDetails} notFound={vehicleDetailsNotFound} error={lookupVehicleDetailsError}/>,
                <Confirm key={2} confirming={addingVehicle} onConfirm={this.doConfirmVehicle} onCancel={this.doResetLookup}/>
                ]: <div> </div>
              }
              {addVehicleError ? <Message error header='We had a problem adding your vehicle' content={addVehicleError} size='tiny'/> : null}

              {!hideBackLink ? <Segment basic textAlign='center'><p>Back to <Link to="/dashboard">your dashboard</Link></p></Segment> : null}
          
          
          </Grid.Column>
          </Grid>
          </div>
          </div>
        );
    }
}

const mapStateToProps = state => (
  {...state.addVehicle}
)

const mapDispatchToProps = dispatch => bindActionCreators({
  onStartNewLookup,
  onLookupVehicleDetails,
  onAddNewVehicle
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddVehicle)
