import React, {Component} from 'react'
import PropTypes from 'prop-types';
import {getPaymentIntentSecret, reportPaymentIntent} from '../../modules/payment/payment'
import { Segment, Button, Form, Message } from 'semantic-ui-react';
import { injectStripe } from 'react-stripe-elements';
import { Redirect } from 'react-router-dom'

const stripeElementOptions = () => {
  return {
      'style' : {
          'invalid': {
              'color': "rgb(159, 58, 56)"
          }
      }
  };
};

class ApproveCSP extends Component {

    constructor(props) {
        super(props);
        this.sessionId = window.location.pathname.split("/").pop()
        this.state = {
            submitted: false
        };
    }

    handleSubmit = (ev) => {
        ev.preventDefault();
        getPaymentIntentSecret(this.sessionId).then(paymentIntentSecret => {
            this.props.stripe.handleCardPayment( paymentIntentSecret.secret, {})
              .then(response => {
                  const error = response.error;
                  if(error) {
                      // TODO: do more with error message from Stripe
                      console.log(error)
                      reportPaymentIntent(this.sessionId).then( response =>
                          alert("Payment failed - " + error)
                      )
                  } else {
                      reportPaymentIntent(this.sessionId).then(
                          this.setState({submitted: true})
                      )
                  }
              });
        });
    };

  render() {
    const { creating, error} = this.props;

    if (this.state.submitted) {
        return (
            <Redirect to="/dashboard"/>
        )
    } else {
        return (
            <Segment>
                {error ? <Message negative>{error}</Message> : null}
                <Form>
                    <Button.Group widths={1}>
                        <Button color='blue' onClick={this.handleSubmit}>Approve charge</Button>
                    </Button.Group>
                </Form>
            </Segment>
        );
    }
  }
}

ApproveCSP.propTypes = {
    error: PropTypes.string,
};

export default injectStripe(ApproveCSP);