import React, {Component} from 'react'
import PropTypes from 'prop-types'
import { Button } from 'semantic-ui-react'

class ConfirmVehicle extends Component {
  
  render() {
    const { confirming, onConfirm, onCancel } = this.props;
    return [
        <div>
          <div className="not-my-vehicle"><a id="not-my-vehicle" onClick={() => onCancel()} disabled={confirming}>This is not my vehicle</a></div>
        <p>Hozah will take automatic payment when this vehicle visits a Hozah-enabled car park.</p>
        <Button.Group  key={1} widths={2} size='large'>
            <Button id="confirm-vehicle-new" color='green' onClick={() => onConfirm()} disabled={confirming} loading={confirming}>Continue</Button>
        </Button.Group>

        </div>
    ];
  }
}

ConfirmVehicle.propTypes = {
  confirming: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default ConfirmVehicle