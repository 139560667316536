import React, {Component} from 'react'
import { connect } from 'react-redux'

class Footer extends React.Component {

  render() {
        return (
            <div>
            {/* <div className="section section-light-grey section-small">
                <div className="w-container">
                    <div className="icon-list">
                        <div className="icon-list-item"><img src="images/Cash.png" width="64" alt="" className="icon" />
                            <p className="icon-description">No need to carry cash</p>
                        </div>
                        <div className="icon-list-item"><img src="images/Clock.png" width="64" alt="" className="icon" />
                            <p className="icon-description">No waiting at the ticket machine</p>
                        </div>
                        <div className="icon-list-item"><img src="images/Phone.png" width="64" alt="" className="icon" />
                            <p className="icon-description">No need to download an app</p>
                        </div>
                        <div className="icon-list-item"><img src="images/H-logo.png" width="64" alt="" className="icon" />
                            <p className="icon-description"><strong>Simply park and go</strong></p>
                        </div>
                    </div>
                </div>
            </div> */}
                <a href="https://support.hozah.com" target="_blank" rel="noopener noreferrer"
                   className="section section-link section-small w-inline-block"><img src="images/Email.png" width="64"
                                                                                      alt="" className="icon" /><p
                    className="section-link-text">Got a question? <span
                    className="bold-link">Visit Hozah Support</span></p></a>
      <div className="section footer">
          <div className="w-container">
              <div className="w-row">
                  <div className="w-clearfix w-col w-col-6"><img src="images/hozah-bpa-member.png"
                                                                 srcSet="images/hozah-bpa-member-p-500.png 500w, images/hozah-bpa-member-p-800.png 800w, images/hozah-bpa-member.png 960w"
                                                                 sizes="(max-width: 479px) 84vw, 200px" alt=""
                                                                 className="footer-logo bpa" /></div>
                  <div className="w-clearfix w-col w-col-6"><img src="images/IPC-logo.png"
                                                                 srcSet="images/IPC-logo-p-500.png 500w, images/IPC-logo.png 800w"
                                                                 sizes="(max-width: 479px) 84vw, 200px" alt=""
                                                                 className="footer-logo ipc" /></div>
              </div>
              <div className="footer-links"><a href="https://hozah.com/terms/" target="_blank" className="footer-link">Terms
                  and conditions</a><a href="https://hozah.com/privacy-and-cookies/" target="_blank"
                                       className="footer-link">Privacy Policy &amp; Cookie Policy</a><a
                  href="https://hozah.com/cookies/" target="_blank" className="footer-link">Important Cookie
                  Information</a></div>
              <div className="footer-legal">© 2023 Hozah. All rights reserved. Hozah Ltd, Castle House, Castle Street, Guildford GU1 3UW.</div>
          </div>
      </div>
            </div>
        );
  }
}



export default connect()(Footer)
