import React, {Component} from 'react'
import { Message, Loader, Form, Segment, Input, Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

class ForgotPasswordForm extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            submitting: false,
            submitted: false,
            error: false,
            emailAddress: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({
            ...this.state,
            emailAddress: event.target.value
        })
    }

    handleSubmit(event) {
        const { onSubmit } = this.props;
        const { emailAddress } = this.state;
        event.preventDefault();
        this.setState({
            ...this.state,
            submitting: true
        })
        onSubmit(emailAddress)
            .then(() => {
                this.setState({ 
                    ...this.state,
                    submitted: true,
                    submitting: false,
                    error: false
                });
            })
            .catch((error) => {
                this.setState({ 
                    ...this.state,
                    submitted: false,
                    submitting: false,
                    error: true
                });
            })
    }

    componentWillMount() {
        this.setState({ 
            submitting: false,
            submitted: false,
            error: false,
            emailAddress: ''
        }); 
    }

    render() {
        const { submitting, submitted, error, emailAddress } = this.state;
        if(submitted) {
            return <Message
                info
                icon='info'
                header='Check your email!'
                content={<p>We've sent an email to <strong>{emailAddress}</strong> containing a link to reset your password</p>}
            />
        } else {
            return (
                <div>
                    {error ? <Message error content='There was a problem resetting your password. Please try again'/> : null}
                    <Segment secondary>
                        <Segment basic>
                            <h2>Forgot your password?</h2>
                            <Form onSubmit={this.handleSubmit} onChange={this.handleChange}>
                                <Form.Input name="emailAddress" value={emailAddress} type="text" placeholder="Enter your email address"/>
                                <Button color='green' size='large' type="submit" disabled={submitting} loading={submitting}>Reset password</Button>
                            </Form>
                            <p>or <a href="/signup">click here</a> to sign up</p>
                        </Segment>
                    </Segment>
                </div>
            );
        }
    }
}

export default ForgotPasswordForm;
