import { CALL_API } from 'redux-api-middleware';
import hozahConfig from '../../config/HozahConfig';
import Cookies from 'universal-cookie'

export const FETCH_CARD_DETAILS = 'payment/FETCH_CARD_DETAILS'
export const FETCHED_CARD_DETAILS = 'payment/FETCHED_CARD_DETAILS'
export const FAILED_TO_FETCH_CARD_DETAILS = 'payment/FAILED_TO_FETCH_CARD_DETAILS'
export const CREATE_CARD = 'payment/CREATE_CARD'
export const CREATED_CARD = 'payment/CREATED_CARD'
export const FAILED_TO_ASSIGN_CARD = 'payment/FAILED_TO_ASSIGN_CARD'
export const FAILED_TO_SETUP_CARD = 'payment/FAILED_TO_SETUP_CARD'
export const SWITCH_TO_UPDATE_CARD_DETAILS = 'payment/SWITCH_TO_UPDATE_CARD_DETAILS'
export const SWITCH_TO_VIEW_CARD_DETAILS = 'payment/SWITCH_TO_VIEW_CARD_DETAILS'
export const FETCH_PAYMENT_SECRET = 'payment/FETCH_PAYMENT_SECRET'
export const FETCHED_PAYMENT_SECRET = 'payment/FETCHED_PAYMENT_SECRET'
export const FAILED_TO_FETCH_PAYMENT_SECRET = 'payment/FAILED_TO_FETCH_PAYMENT_SECRET'

export const onFetchCardDetails = () => {
    return dispatch => {
        dispatch({
            [CALL_API]: {
                endpoint: '/card-details',
                method: 'GET',
                types: [FETCH_CARD_DETAILS, FETCHED_CARD_DETAILS,
                  {
                    type: FAILED_TO_FETCH_CARD_DETAILS,
                    meta: (action, state, res) => {
                      if (res.status == 404) {
                        return {
                          notFound: true
                        };
                      } else {
                        return {};
                      }
                    }
                  }]
            }
        });
    }
}

export const onFetchPaymentIntentSecret = (subscriptionPeriodId) => {
    return dispatch => {
        dispatch({
            [CALL_API]: {
                endpoint: '/client-secret/'+ subscriptionPeriodId,
                method: 'GET',
                types: [FETCH_PAYMENT_SECRET, FETCHED_PAYMENT_SECRET, FAILED_TO_FETCH_PAYMENT_SECRET]
            }
        });
    }
}

export const onSaveCardDetails = () => {
  return dispatch => {
        dispatch({
          type: CREATE_CARD
        });
    }
}

export const onCancelUpdatingCardDetails = () => {
  return dispatch => {
        dispatch({
          type: SWITCH_TO_VIEW_CARD_DETAILS
        });
    }
}

export const onSwitchToUpdateCardDetails = () => {
  return dispatch => {
        dispatch({
          type: SWITCH_TO_UPDATE_CARD_DETAILS
        });
    }
}

export const getSetupId = () => {
    return fetch(hozahConfig.apiUri() + '/setup-id', { method: 'post'})
        .then(
            function(response) {
                if (response.status !== 200) {
                    console.log('Problem generating setup-id. Status Code: ' + response.status);
                    return Promise.reject(response.status);
                }
                return Promise.resolve(response.json());
            }
        )
        .catch((err) => {
            console.log('Problem generating setup-id: ', err);
            return Promise.reject(err)
        });
}

export const getPaymentIntentSecret = (subscriptionPeriodId) => {
    return fetch(hozahConfig.apiUri() + '/client-secret/' + subscriptionPeriodId, { method: 'get'})
        .then(
            function(response) {
                if (response.status !== 200) {
                    console.log('Problem generating payment intent ID. Status Code: ' + response.status);
                    return Promise.reject(response.status);
                }
                return Promise.resolve(response.json());
            }
        )
        .catch((err) => {
            console.log('Problem generating payment intent ID: ', err);
            return Promise.reject(err)
        });
}

export const reportPaymentIntent = (subscriptionPeriodId) => {
    return fetch(hozahConfig.apiUri() + '/report-intent/' + subscriptionPeriodId, { method: 'post'})
        .then(
            function(response) {
                if (response.status !== 200) {
                    console.log('Problem reporting payment intent processing ' + subscriptionPeriodId + '. Status Code: ' + response.status);
                    return Promise.reject(response.status);
                }
                return Promise.resolve();
            }
        )
        .catch((err) => {
            console.log('Problem reporting payment intent processing ' + subscriptionPeriodId + ': ', err);
            return Promise.reject(err)
        });
}

export const onCardSetupSuccess = (setupIntent, nextAction) => {
  return dispatch => {
        dispatch({
            [CALL_API]: {
                endpoint: '/card-details',
                method: 'POST',
                body: JSON.stringify({
                  setup_id: setupIntent.setupId
                }),
                types: [
                    CREATE_CARD,
                    {
                        type: CREATED_CARD,
                        payload: () => {
                            nextAction(dispatch);
                        }
                    },
                    FAILED_TO_ASSIGN_CARD
                ]
            }
        });
    }
}

export const onBuCardSetupSuccess = (nextAction) => {

    const cookies = new Cookies();
    let selectedPaymentBand = cookies.get('selectedPermit')

    console.log("Endpoint onBuCardSetupSuccess cookie:", selectedPaymentBand)
    console.log(typeof selectedPaymentBand)


    return dispatch => {
          dispatch({
              [CALL_API]: {
                  endpoint: '/bu/credits',
                  method: 'POST',
                  body: JSON.stringify({
                    payment_band: selectedPaymentBand
                  }),
                  types: [
                      CREATE_CARD,
                      {
                          type: CREATED_CARD,
                          payload: () => {
                              nextAction(dispatch);
                          }
                      },
                      FAILED_TO_ASSIGN_CARD
                  ]
              }
          });
      }
  }

export const onCardSetupFailed = (error) => {
  return dispatch => {
        dispatch({
          type: FAILED_TO_SETUP_CARD,
          error: error
        });
    }
}

const initialState = {
    cardDetails: null,
    loadingCardDetails: true,
    cardDetailsLoadingError: null,
    editing: false,
    creatingCard: false,
    cardCreationError: null,
    loadingPaymentSecret: null,
    paymentSecret: null,
    paymentSecretError: null
}

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CARD_DETAILS:
            return {
                ...state,
                cardDetails: null,
                loadingCardDetails: true,
                cardDetailsLoadingError: null
            };
        case FETCHED_CARD_DETAILS:
            return {
                ...state,
                cardDetails: action.payload,
                loadingCardDetails: false,
                cardDetailsLoadingError: null,
                editing: action.payload ? false : true
            };
        case FAILED_TO_FETCH_CARD_DETAILS:
            return {
                ...state,
                cardDetails: null,
                loadingCardDetails: false,
                cardDetailsLoadingError: action.meta.notFound ? null : action.payload.statusText,
                editing: action.meta.notFound ? true : false
            };
        case CREATE_CARD:
            return {
                ...state,
                creatingCard: true,
                cardCreationError: null
            };
        case CREATED_CARD:
            return {
                ...state,
                cardDetails: action.payload,
                creatingCard: false,
                cardCreationError: null,
                editing: false
            }
        case FAILED_TO_ASSIGN_CARD:
            return {
                ...state,
                creatingCard: false,
                cardCreationError: "Failed to save card details. Please try again"
            }
        case FAILED_TO_SETUP_CARD:
            return {
                ...state,
                creatingCard: false,
                cardCreationError: "Failed to save card details. Please try again"
            }
        case SWITCH_TO_UPDATE_CARD_DETAILS:
            return {
                ...state,
                editing: true
            }
        case SWITCH_TO_VIEW_CARD_DETAILS:
            return {
                ...state,
                editing: false
            }
        case FETCH_PAYMENT_SECRET:
            return {
                ...state,
                loadingPaymentSecret: true,
                paymentSecretError: null
            }
        case FETCHED_PAYMENT_SECRET:
            return {
                ...state,
                loadingPaymentSecret: false,
                paymentSecret: action.payload,
                paymentSecretError: null
            }
        case FAILED_TO_FETCH_PAYMENT_SECRET:
            return {
                ...state,
                loadingPaymentSecret: false,
                paymentSecretError: action.payload
            }
        default:
            return state;
    }
}