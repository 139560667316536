import React, {Component} from 'react'
import queryString from 'query-string'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { verifyEmailAddress, recoverEmailAddress, verifyPasswordResetCode, resetPassword } from '../../modules/auth'
import { Message, Loader, Segment } from 'semantic-ui-react'
import { Redirect } from 'react-router-dom'
import VerifyEmailAddress from '../../components/auth/VerifyEmailAddress'
import RecoverEmailAddress from '../../components/auth/RecoverEmailAddress'
import ResetPasswordForm from '../../components/auth/ResetPasswordForm'
import { Link } from 'react-router-dom'

class FirebaseCallback extends Component {

    render() {
        const firebaseQueryParameters = queryString.parse(window.location.search);
        const mode = firebaseQueryParameters.mode;
        const actionCode = firebaseQueryParameters.oobCode;

        if(mode === 'verifyEmail') {
            return <VerifyEmailAddress onVerifyEmailAddress={verifyEmailAddress(actionCode)}/>
        } else if(mode === 'recoverEmail') {
            return <RecoverEmailAddress onRecoverEmailAddress={recoverEmailAddress(actionCode)}/>
        } else if(mode === 'resetPassword') {
            return (
                <div>
                    <Segment basic textAlign='center'>
                        <h2>Reset your password</h2>
                    </Segment>
                    <ResetPasswordForm onVerifyActionCode={verifyPasswordResetCode(actionCode)} onSubmit={resetPassword(actionCode)}/>
                    <Segment basic textAlign='center'>
                        <p><Link to="/login">click here</Link> to login</p>
                    </Segment>
                </div>
            );
        } else {
            return <Redirect to="/login"/>
        }
    }
}

export default FirebaseCallback;
