import React, {Component} from 'react'
import PropTypes from 'prop-types';
import {getSetupId} from '../../../modules/payment/payment'
import { Segment, Button, Form, Message } from 'semantic-ui-react';
import { injectStripe, CardNumberElement, CardExpiryElement, CardCVCElement } from 'react-stripe-elements';
import { push } from "react-router-redux";

const stripeElementOptions = () => {
  return {
      'style' : {
          'invalid': {
              'color': "rgb(159, 58, 56)"
          }
      }
  };
};

class UpdateCardDetails extends Component {

  constructor(props) {
      super(props);
  }

  state = {
      cardComplete: false,
      cvcComplete: false,
      expiryComplete: false,
  }

    dispatchToWelcome = (dispatch) => {
        dispatch(push('/welcome'))
    };

    dispatchToDashboard = (dispatch) => {
        dispatch(push('/dashboard'))
    };

    handleSubmitRedirect = (redirect) => (ev) => {
        ev.preventDefault();
        this.props.onSave();
        getSetupId().then(setupIntent => {
            this.props.stripe.handleCardSetup( setupIntent.secret, {})
              .then(response => {
                  const error = response.error;
                  if(error) {
                      // TODO: do more with error message from Stripe
                      this.props.onCardSetupFailed(error);
                  } else {
                      this.props.onCardSetupSuccess(setupIntent, redirect);
                  }
              });
        });
    };

    handleCardNumberChanged = (changeObject) => {
      this.setState({
        ...this.state,
        cardComplete: changeObject.complete
      });
    }

    handleCvcChanged = (changeObject) => {
      this.setState({
        ...this.state,
        cvcComplete: changeObject.complete
      });
    }

  handleExpiryChanged = (changeObject) => {
      this.setState({
        ...this.state,
        expiryComplete: changeObject.complete
      });
  }

  render() {
    const { creating, onSave, onCancel, error, initial } = this.props;
    const { cardComplete, cvcComplete, expiryComplete } = this.state;
    const complete = cardComplete && cvcComplete && expiryComplete;

    return (
        <Segment basic loading={creating}>
            {error ? <Message negative>{error}</Message> : null}
            <Form>
                <Form.Field width={30} >
                    <CardNumberElement id="card-number" placeholder={"Card number"} onChange={this.handleCardNumberChanged.bind(this)} {...stripeElementOptions()}/>
                </Form.Field>
                <Form.Field width={30}>
                    <CardCVCElement placeholder={"CVC"} onChange={this.handleCvcChanged.bind(this)} {...stripeElementOptions()}/>
                </Form.Field>
                <Form.Field width={30}>
                    <CardExpiryElement placeholder={"Expiry date"} onChange={this.handleExpiryChanged.bind(this)} {...stripeElementOptions()}/>
                </Form.Field>
                <Button.Group widths={2}>
                    {initial ? <Button id="submit-payment-details" color='green' onClick={this.handleSubmitRedirect(this.dispatchToWelcome)} disabled={!complete}>Save your details</Button> : <Button color='green' onClick={this.handleSubmitRedirect(this.dispatchToDashboard)} disabled={!complete}>Update your details</Button> }
                    {onCancel ? <Button color='grey' onClick={() => onCancel()}>Cancel</Button>: null}
                </Button.Group>
            </Form>
        </Segment>
    );
  }
}

UpdateCardDetails.propTypes = {
    creating: PropTypes.bool,
    error: PropTypes.string,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    onCardSetupSuccess: PropTypes.func.isRequired,
    onCardSetupFailed: PropTypes.func.isRequired
};

export default injectStripe(UpdateCardDetails);