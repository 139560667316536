import { CALL_API } from 'redux-api-middleware';
import HozahConfig from '../config/HozahConfig';
import isNil from 'lodash/isNil';
import get from 'lodash/get';

export default () => (next) => action => {
    const callApi = action[CALL_API];

    if (callApi) {

        // Append the Front API base URI if there's not already a base URI specified
        if (!/^((http|https|ftp):\/\/)/i.test(callApi.endpoint)) {
            callApi.endpoint = `${HozahConfig.apiUri()}${callApi.endpoint}`;
        }

        // Add the Auth0 id and access tokens to the request
        callApi.headers = Object.assign({}, callApi.headers, {
            "X-HOZAH-ID-TOKEN": localStorage.getItem("id_token")
        });

        // Set Content-Type to application/json if Content-Type does not already have a value.
        if (callApi.method === 'POST' && isNil(get(callApi.headers, 'Content-Type', null))) {
            callApi.headers['Content-Type'] = 'application/json';
        }
    }
      
    return next(action);
}