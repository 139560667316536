import React from 'react';
import PropTypes from 'prop-types'

import {Grid, Button, List, Container} from 'semantic-ui-react'
import { connect } from 'react-redux'
import {bindActionCreators} from 'redux'
import {Route, Switch, withRouter} from 'react-router-dom'
import Login from '../login'
import {login, logout} from '../../modules/auth'

class BULandingPage extends React.Component {
    static contextTypes = {
        router: PropTypes.shape({
          history: PropTypes.shape({
            push: PropTypes.func.isRequired,
            replace: PropTypes.func.isRequired
          }).isRequired,
          staticContext: PropTypes.object
        }).isRequired
      }
    render() {

        const { history } = this.context.router;

        const BuSignupLink = () => history.push('/bournemouth-university/signup');

        return (
            <Container className="bu-container">
            
            <div className="bu-header"> 
            <h2>Online permit system trial</h2>
            </div>
            <Grid stackable textAlign='left'>
            <Grid.Row columns={2} className="content-container">
            <Grid.Column width={11}>

            <Container className="bu-fonts">Bournemouth University are trialling a system to consider a more flexible parking ‘offer’ for staff permit holders. <strong>Taking part in this trial will not cost participants anything - all payments will be made using a dummy card provided by Hozah.</strong>
            </Container>
            </Grid.Column>
            <Grid.Column width={5}>
            <Button className="bu-sign-up-button" onClick={BuSignupLink}>Set up your pilot parking permit account</Button>
            <Container>
              If you have any questions at any point concerning the trial, please contact Hozah at 
            </Container>
            <Container>
            <strong>hello@hozah.com</strong> or on <strong>0208 315 6535</strong>
            </Container>
            </Grid.Column>
            <Grid.Column width={11}>
            <h3 className="bu-h3">How it works</h3>
            <Container className="bu-fonts">The pilot system will work on a dummy day rate for parking and will test technology that will allow for daily ‘dummy’ parking charges to be automatically applied through a seamless payment process. The pilot aims to test if this process could be considered to encourage permit holders to consider their travel behavour on a daily basis in line with the objectives of the <a>BU  Travel Plan 2019-2025</a>. The system will allocate 6 days of parking per month a cheaper rate than normal. For the purposes of the trial, Bournemouth University will ask drivers to sign-up to a Discounted Parking Rate system. Once signed up, drivers will be provided with 6 Discounted Session per calendar month. Each Discount Session will entitle a driver to park for 1 session (working day) at a Discounted Parking Session Rate. There will be no fee for joining the system.   
            <br />
            <br />
            </Container>
            <Container className="bu-fonts">For the purposes of a trial, Bournemouth University will ask drivers to sign-up to a <strong>Discounted Parking Credit System</strong>. Once signed-up, drivers will be provided with X number of Discount Credits per calendar month. Each <strong>Discount Credits</strong> will entitle a driver to park for 1 session (up to 24 hours) at a <strong>Discounted Parking Session Rate.</strong> There will be no fee for joining the system. 
            </Container>
            <br />
            <br />
            </Grid.Column>
            
            <Grid.Column width={11}>
            <h3 className="bu-h3">Parking Session Rates</h3>
            <Container className="bu-fonts">
              There are five different Discounted Parking Session Rate (Rate A, Rate B, Rate C, Rate D, Rate E). Please choose the Discounted Parking Session Rate communicated to you by the University for the purpose of the trial. Drivers on each rate will "pay" the following prices per day of discounted parking:
            </Container>
            <Container className="bu-fonts">
                <List className="bu-list">
                    <List.Item><strong>Discounted Parking Session Rate A:</strong> £0.50 per session </List.Item>
                    <List.Item><strong>Discounted Parking Session Rate B:</strong> £0.60 per session </List.Item>
                    <List.Item><strong>Discounted Parking Session Rate C:</strong> £0.70 per session </List.Item>
                    <List.Item><strong>Discounted Parking Session Rate D:</strong> £0.80 per session </List.Item>
                    <List.Item><strong>Discounted Parking Session Rate E:</strong> £0.90 per session </List.Item>
                </List>
            </Container>
            <Container className="bu-fonts">
            If a driver parks and all Discount Session rates have been used then the driver will automatically pay the Default Parking Session Rate for parking. The Default Parking Session Rate will, for the purpose of the trial, be £2.10.
            </Container>
            </Grid.Column>

            </Grid.Row>
            </Grid>
            </Container>

        );
  }
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    initialising: state.auth.initialising
  })
  
  const mapDispatchToProps = dispatch => bindActionCreators({
    login,
    logout
  }, dispatch)

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
  )(BULandingPage))