import React, {Component} from 'react'
import ApproveCSP from '../../components/payment/ApproveCSP'
import { onFetchPaymentIntentSecret } from '../../modules/payment/payment'
import { Elements } from 'react-stripe-elements';
import { Segment, Divider, Loader, Header, Icon, Message } from 'semantic-ui-react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'


class PaymentAuth extends Component {

    constructor(props) {
        super(props);
        this.sessionId = window.location.pathname.split("/").pop()
    }

    componentWillMount() {
        this.props.onFetchPaymentIntentSecret(this.sessionId)
    }

    render() {
        const { loadingPaymentSecret, paymentSecret, paymentSecretError } = this.props;
        var component

        if( loadingPaymentSecret ) {
            component = <Loading {... this.props}/>
        } else if ( paymentSecret ) {
            component =
                <Elements>
                    <ApproveCSP />
                </Elements>
        } else if (paymentSecretError && paymentSecretError.status == 404) {
            component = <Redirect to="/dashboard"/>
        }
        else {
            component =
                <Elements>
                    <Segment>
                        Error while attempting to authenticate
                    </Segment>
                </Elements>
        }
        return component
    }
}

const Loading = (props) => {
    return (
        <Loader active inline='centered' size='small'>Loading authentication</Loader>
    )
}

const mapStateToProps = state => ({
    cardDetails: state.payment.cardDetails,
    loadingCardDetails: state.payment.loadingCardDetails,
    cardDetailsLoadingError: state.payment.cardDetailsLoadingError,
    editing: state.payment.editing,
    creatingCard: state.payment.creatingCard,
    cardCreationError: state.payment.cardCreationError,
    loadingPaymentSecret: state.payment.loadingPaymentSecret,
    paymentSecret: state.payment.paymentSecret,
    paymentSecretError: state.payment.paymentSecretError
})

const mapDispatchToProps = dispatch => bindActionCreators({
    onFetchPaymentIntentSecret
}, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PaymentAuth)