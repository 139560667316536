class FirebaseConfig {
    
    apiKey() {
        return window.config.firebase.apiKey;
    }

    authDomain() {
        return window.config.firebase.authDomain;
    }

    databaseURL() {
        return `https://${window.config.firebase.databaseName}.firebaseio.com`;
    }
}

const firebaseConfig = new FirebaseConfig();

export default firebaseConfig;