import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

const Home = props => (
  <Redirect to="/dashboard" />
)

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  displayName: state.auth.user ? state.auth.user.displayName : ''
})

export default connect(
  mapStateToProps
)(Home)
