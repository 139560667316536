import React, {Component} from 'react'
import PropTypes from 'prop-types'
import { Segment, Header, List, Icon, Card, Label } from 'semantic-ui-react';

const NotFound = () => (
     <Segment secondary textAlign='center'>
        <Label pointing>
               <p> <Icon name='frown' /> We couldn't find details for that registration number </p>
               <p> Have you typed it correctly? </p>
            </Label>
            <br />
     </Segment>
)

const LookupError = (props) => (
    <Segment secondary textAlign='center'>
    <Label pointing>
           <p> <Icon name='frown' /> We had a problem looking up your registration number </p>
           <p> Please try again </p>
        </Label>
        <br />
 </Segment>
)

class VehicleDetails extends Component {

    render() {
        const { data, notFound, error } = this.props;
        
        if(notFound) {
            return <NotFound/>
        } else if(error) {
            return <LookupError/>
        } else {
            return (
                <Segment class={"form"} secondary>
                    <Header as='h3' className="vinfo-header-bu">
                        Vehicle information
                    </Header>
                    <Card.Content centered className="bu-card-color">
                        <Card.Header className="card-header-bu"><strong><img src="images/car-icon.svg" width="25" alt="" className="car-icon" />{data.vrm}</strong></Card.Header><br />
                        <Card.Description className="bu-fonts"> {data.make}, {data.colour}, {data.fuelType}, {data.yearOfManufacture}</Card.Description>
                    </Card.Content>
                </Segment>
            );
        }
    }
}

VehicleDetails.propTypes = {
    data: PropTypes.shape({
        make: PropTypes.string.isRequired,
        colour: PropTypes.string.isRequired,
        fuelType: PropTypes.string.isRequired,
        yearOfManufacture: PropTypes.number.isRequired
    }),
    notFound: PropTypes.bool,
    error: PropTypes.string
};

export default VehicleDetails;