import React, {Component} from 'react'
import { Segment } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import ForgotPasswordForm from '../../components/auth/ForgotPasswordForm'
import { triggerResetPassword } from '../../modules/auth'

class ForgotPassword extends Component {

    render() {
        return (
            <div>
              <ForgotPasswordForm onSubmit={triggerResetPassword}/>
            </div>
        );
    }
}

export default ForgotPassword