import React, {Component} from 'react'
import { connect } from 'react-redux'
import {Button, Dropdown, Header, Loader, Menu, Segment, Container, Grid} from 'semantic-ui-react'


class BuFooter extends React.Component {

  render() {
        return (
          <Container className="bu-container bu-menu">
          <Grid>
          <Grid.Row columns={1} className="content-container">
          <Grid.Column textAlign='right' width={18} className="poweredBy">

            <span>
            Powered by
            </span>
            <img className="hozahlogo" src="images/hozah_logo.png" alt="hozah_logo" />
            </Grid.Column>
            </Grid.Row>

            </Grid>
            </Container>
            );
  }
}



export default connect()(BuFooter)