import React, {Component} from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import { Segment, Input, Form, Button, Message, Label, Icon, Container, Grid, Card } from 'semantic-ui-react'
import { get, validatePassword } from '../../modules/utils'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { loginWithGoogle, loginWithFacebook } from '../../modules/auth'
import { Redirect } from 'react-router-dom'
import queryString from 'query-string'
import { bindActionCreators } from 'redux'
import {onAddNewPermit} from "../../modules/permits/permits";
import Cookies from 'universal-cookie';


class PermitForm extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            selectedPermit: "A",
            permits: [{
                permitCode: "A",
                permitRate: "0.50"
            },
            {
                permitCode: "B",
                permitRate: "0.60"
            },
            {
                permitCode: "C",
                permitRate: "0.70"
            },
            {
                permitCode: "D",
                permitRate: "0.80"
            },
            {
                permitCode: "E",
                permitRate: "0.90"
            },
        ]
        };
    }
    
    permitSelected(permit){

        this.setState({
            selectedPermit: permit,
            });
    }


    render() {

        const { redirectTo } = this.props

        const cookies = new Cookies();

        const handleSubmit = ()=>{

        cookies.set('selectedPermit', this.state.selectedPermit, { path: '/' });
        window.location.reload(false);
        }

            return (    

                    <>
                        <Form className="form-new">
                        <Grid.Column>
                            <Container className="bu-fonts permit-text">
                            Please choose the Discounted Parking Session Rate communicated to you by the University for the purpose of the trial. 
                            </Container>
                        </Grid.Column> 
                            <Grid columns='equal' stackable textAlign='left'>
                            <Grid.Row columns={5}>
                            {this.state.permits.map((permit) =>
                                <Grid.Column>
                                <Card className={ permit.permitCode === this.state.selectedPermit ? "selected" : "" } onClick={this.permitSelected.bind(this, permit.permitCode)}>
                                    <Card.Content  textAlign='center'>
                                    <Card.Header >
                                    <p className="card-header-bu">RATE</p>
                                    <h2 className="card-header-bu">{permit.permitCode}</h2>
                                    </Card.Header>
                                    <p className="bu-fonts">£{permit.permitRate} per session</p>
                                    </Card.Content>
                                </Card>
                                </Grid.Column>
                            )}
                            </Grid.Row>
                            </Grid>
                            <br />
                            <Button id="signupForm-new" className="bu-permit-button" size='massive' type="submit" onClick={handleSubmit}>Continue to your details</Button>
                        </Form>
                        </>
                      
            );
        
    }
}


export default PermitForm