import React, {Component} from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Segment, Divider, Loader, Header, Icon, Message, Button } from 'semantic-ui-react'
import ActiveSession from '../../components/activeSessions/ActiveSession'
import { onFetchActiveSession, onRefreshActiveSession } from '../../modules/activeSessions/activeSession'
import { Link } from 'react-router-dom'

class ActiveSessionContainer extends Component {

    constructor(props) {
        super(props);

        this.sessionId = this.props.computedMatch.params.id
        const { onRefreshActiveSession } = this.props;

        var hidden, visibilityChange;
        
        if (typeof document.hidden !== "undefined") {
            hidden = "hidden";
            visibilityChange = "visibilitychange";
        } else if (typeof document.mozHidden !== "undefined") {
            hidden = "mozHidden";
            visibilityChange = "mozvisibilitychange";
        } else if (typeof document.msHidden !== "undefined") {
            hidden = "msHidden";
            visibilityChange = "msvisibilitychange";
        } else if (typeof document.webkitHidden !== "undefined") {
            hidden = "webkitHidden";
            visibilityChange = "webkitvisibilitychange";
        }

        this.hidden = hidden;
        this.visibilityChange = visibilityChange;
        this.onVisibilityChanged = () => {
            if(!document[hidden]) {
                onRefreshActiveSession(this.sessionId);
            }
        }
    }

    componentWillMount() {
        const { onFetchActiveSession } = this.props;
        onFetchActiveSession(this.sessionId);
        setTimeout(this.handleRefreshActiveSession(), (60 - new Date().getSeconds()) * 1000);
        document.addEventListener(this.visibilityChange, this.onVisibilityChanged, false);
    }

    componentWillUnmount() {
         document.removeEventListener(this.visibilityChange, this.onVisibilityChanged);
    }

    handleFetchActiveSession() {
        const { onFetchActiveSession } = this.props;
        return () => {
            onFetchActiveSession(this.sessionId)
        }
    }

    handleRefreshActiveSession() {
        const { onRefreshActiveSession } = this.props;
        return () => {
            if(!document[this.hidden]) {
                onRefreshActiveSession(this.sessionId);
            }
            setTimeout(this.handleRefreshActiveSession(), 60000);
        }
    }

    render() {
        const { activeSession, fetching, fetchError } = this.props;

        var component = null

        if (fetching) {
            return (<Loading {... this.props}/>);
        } else if (fetchError) {
            component = (
                <FetchError onTryAgain={this.handleFetchActiveSession()}/>
            );
        } else {
            if(!activeSession) {
                component = <NotFound/>
            } else {
                component = <ActiveSession {...activeSession}/>;
            }
        }
        return (
            <div>
              {component}
              <Button color='grey' fluid>Contact parking operator</Button>
              <Segment basic textAlign='center'>
                <p>Back to <Link to="/dashboard">your dashboard</Link></p>
              </Segment>
            </div>
        );
    }
}

const NotFound = () => {
    return (
        <Segment disabled secondary textAlign='center'>
            <Header as='h4' icon>
                <Icon name='frown' color='grey' />
                    Could not find session information
            </Header>
        </Segment>
    );
}

const FetchError = (props) => {
    return (
        <Segment disabled secondary textAlign='center'>
            <Header as='h4' icon>
                <Icon name='frown' />
                    Failed to load active session
                <Header.Subheader>
                    Please&nbsp;<a href='#' onClick={(e) => {e.preventDefault(); props.onTryAgain(); }}>click here</a>&nbsp;to try again.
                </Header.Subheader>
            </Header>
        </Segment>
    );
}

const Loading = (props) => {
  return (
    <Loader active inline='centered' size='small'>Loading session information</Loader>
  )
}

const mapStateToProps = state => ({
      activeSession: state.activeSession.activeSession,
      fetching: state.activeSession.fetching,
      fetchError: state.activeSession.fetchError
})

const mapDispatchToProps = dispatch => bindActionCreators({
    onFetchActiveSession,
    onRefreshActiveSession
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActiveSessionContainer)
