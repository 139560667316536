import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Input, Menu, Segment, Container, Button, Message } from 'semantic-ui-react';

class AssignNewPermit extends Component {

  handleAssignClick = () => {
    const permitCode = this._permitCode.value;
    this._permitCode.value = "";
    this.props.onAssign(permitCode);
  };

  render() {
        const { adding, permitCodeError } = this.props;
        const className = "ui action input fluid" + (adding ? " disabled" : "");
        return (
            <div>
                <Segment color='blue' inverted>
                    <div className={className}>
                        <input type="text" id="permitcodeinput" ref={input => this._permitCode = input} placeholder="Enter new permit code..."/>
                        <Button color='green' onClick={() => {
                            const warning = (document.getElementById("permitcodeinput").value.toLowerCase() === "crewe1") ? "By entering this code, you agree that we can email you on behalf of The Market shopping centre, Crewe" : null;
                            if (warning) {
                                if (window.confirm(warning)) {
                                    this.handleAssignClick()
                                }
                            } else {
                                this.handleAssignClick()
                            }}
                        } loading={adding} disabled={adding}>Assign</Button>
                    </div>
                </Segment>
                {permitCodeError ? <Message color='red' size='tiny'>{permitCodeError}</Message> : null}
            </div>
        );
  }
}

AssignNewPermit.propTypes = {
    onAssign: PropTypes.func.isRequired,
    adding: PropTypes.bool.isRequired,
    permitCodeError: PropTypes.string
};

export default AssignNewPermit