import React, {Component} from 'react'
import { Message, Loader, Form, Segment, Input, Button, Label } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { validatePassword } from '../../modules/utils'

class ResetPasswordForm extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            verifyingActionCode: true,
            invalidActionCode: false,
            submitting: false,
            submitted: false,
            pristine: true,
            validationError: null,
            newPassword: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        const newPassword = event.target.value
        const validationError = validatePassword(newPassword)
    
        this.setState({
            ...this.state,
            newPassword: event.target.value,
            validationError: validationError,
            pristine: false
        })
    }

    handleSubmit(event) {
        const { onSubmit } = this.props;
        const { newPassword } = this.state;
        event.preventDefault();
        this.setState({
            ...this.state,
            submitting: true
        })
        onSubmit(newPassword)
            .then(() => {
                this.setState({ 
                    ...this.state,
                    submitted: true,
                    submitting: false
                });
            })
            .catch((error) => {
                this.setState({ 
                    ...this.state,
                    submitted: false,
                    submitting: false,
                    invalidActionCode: true
                });
            })
    }

    componentWillMount() {
        const { onVerifyActionCode } = this.props;
        this.setState({ 
            verifyingActionCode: true,
            invalidActionCode: false,
            submitting: false,
            submitted: false,
            pristine: true,
            validationError: null,
            newPassword: ''
        });

        onVerifyActionCode()
            .then(() => {
                this.setState({ 
                    ...this.state,
                    verifyingActionCode: false,
                    invalidActionCode: false
                });
            })
            .catch((error) => {
                this.setState({ 
                    ...this.state,
                    verifyingActionCode: false,
                    invalidActionCode: true
                });
            })
    }

    render() {
        const { verifyingActionCode, invalidActionCode, submitting, submitted, pristine, validationError, newPassword } = this.state;
        if(verifyingActionCode) {
            return <Loader active inline='centered' size='large'/>
        } else if(invalidActionCode) {
            return <Message
                error
                icon='frown'
                header='Reset password failed!'
                content={<p>The reset password code is invalid or has expired</p>}
            />
        } else if(submitted) {
            return <Message
                success
                icon='checkmark'
                header='Your password has been reset!'
                content={<p>Please <Link to="/login">click here</Link> to login with your new password</p>}
            />
        } else {
            return (
                <div>
                    <Segment secondary>
                        <Segment basic>
                            <Form onSubmit={this.handleSubmit} onChange={this.handleChange}>
                                <Form.Field {... !pristine && validationError ? {error: true} : {}}>
                                    <input name="password" placeholder="Enter your new password" type='password' value={newPassword}/>
                                    {!pristine && validationError ? <Label basic color='red' pointing>{validationError}</Label>: null}
                                </Form.Field>
                                <Button fluid color='green' size='large' type="submit" disabled={submitting || pristine || validationError} loading={submitting}>Continue</Button>
                            </Form>
                        </Segment>
                    </Segment>
                </div>
            );
        }
    }
}

export default ResetPasswordForm;
