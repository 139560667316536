import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'react-router-redux'
import store, { history } from './store'
import App from './containers/app'

import stripeConfig from './config/StripeConfig'
import {StripeProvider} from 'react-stripe-elements';
import { init as initAuth} from './modules/auth';


import TagManager from 'react-gtm-module'


if (window.config.GoogleAnalytics.GoogleAnalyticsActive === "true") {
  const tagManagerArgs = {
    gtmId: window.config.GoogleAnalytics.GoogleTagManagerId
  }
  TagManager.initialize(tagManagerArgs)
}

initAuth((action) => {
  store.dispatch(action);
});

const target = document.querySelector('#root')

render(
  <StripeProvider apiKey={stripeConfig.publicApiKey()}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
          <App />
      </ConnectedRouter>
    </Provider>
  </StripeProvider>,
  target
)