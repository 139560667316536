import React, {Component} from 'react'
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Segment, Grid, Icon} from 'semantic-ui-react';

const clickable ={ 
    'display': 'block',
    'colour': 'black'
};

const headingStyle = { 'color': 'black' }

const handleClick = (props) => {
    const { name, expanded, onExpand, onCollapse } = props;
    return (e) => {
        e.preventDefault();
        if(expanded) {
            onCollapse(name);
        } else {
            onExpand(name);
        }
    }
} 

const ExpandableSection = (props) => {
    return (
        <Segment vertical>
            <a href="" style={clickable} onClick={handleClick(props)}>
                <Grid columns={2} verticalAlign='middle'>
                    <Grid.Column width={14} textAlign='left'>
                        <h3 style={headingStyle}>{props.heading}</h3>
                    </Grid.Column>
                    <Grid.Column width={2} textAlign='right'>
                        <Icon name={props.expanded ? 'caret up' : 'caret down'} size='large' color={props.expanded ? 'black' : 'grey'}/>
                    </Grid.Column>
                </Grid>
            </a>
            { props.expanded ? props.children : null }
        </Segment>
    );
};

ExpandableSection.propTypes = {
    name: PropTypes.string.isRequired,
    onExpand: PropTypes.func.isRequired,
    onCollapse: PropTypes.func.isRequired,
    heading: PropTypes.string.isRequired,
    expanded: PropTypes.bool,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired
};

export default ExpandableSection