import React, {Component} from 'react'
import { Message, Loader } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

class RecoverEmailAddress extends Component {

    constructor(props) {
      super(props);
    }

    state = { 
        recovering: true,
        recovered: false,
        restoredEmailAddress: null
    }

    componentWillMount() {
        const { onRecoverEmailAddress } = this.props;
        onRecoverEmailAddress()
            .then((restoredEmailAddress) => {
                this.setState({ 
                    recovering: false,
                    recovered: true,
                    restoredEmailAddress: restoredEmailAddress
                });
            })
            .catch((error) => {
                this.setState({ 
                    recovering: false,
                    recovered: false,
                    restoredEmailAddress: null
                });
            })
    }

    render() {
        const { recovering, recovered, restoredEmailAddress } = this.state;

        if(recovering) {
            return <Loading message='Recovering email address'/>
        } else if(recovered) {
            return <EmailRecovered emailAddress={restoredEmailAddress} />
        } else {
            return <EmailRecoveryFailed/>
        }
    }
}

const EmailRecovered = (props) => {
    const {emailAddress} = props;
    return (
        <Message
            success
            icon='checkmark'
            header='Email recovery successful!'
            content={<p>We've restored your email address back to <strong>{emailAddress}</strong>. Please <Link to="/login">click here</Link> to login</p>}
        />
    );
}

const EmailRecoveryFailed = () => {
    return (
        <Message
            error
            icon='frown'
            header='Email recovery failed!'
            content={<p>The email recovery code is invalid or has expired. Please contact us if you want us to send you a new code</p>}
        />
    );
}

const Loading = (props) => {
  return (
    <Loader active inline='centered' size='large'>{props.message}</Loader>
  )
}

export default RecoverEmailAddress;
