import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import LoginForm from '../../components/auth/LoginForm'
import { login } from '../../modules/auth'
import { SubmissionError } from 'redux-form'

class AuthorizedRoute extends React.Component {

  doLogin = (values) => {
    return login(values.emailAddress, values.password)
        .catch( err => {
          // TODO: Check for specific error code type (e.g. email not verified) and show more helpful error message
          throw new SubmissionError({
            _error: err
          })
        });
  }

  render() {
    const { component: Component, isAuthenticated, ...rest } = this.props
    return (
      <Route {...rest} render={props => {
        return isAuthenticated
          ? <Component {...this.props} />
          : <div>
              <LoginForm onSubmit={this.doLogin}/>
            </div>
      }} />
    )
  }
}

const mapStateToProps = ({ auth }) => ({
  isAuthenticated: auth.isAuthenticated
})

export default connect(mapStateToProps)(AuthorizedRoute)