import React, {Component} from 'react'
import { Message, Loader } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

class VerifyEmailAddress extends Component {

    constructor(props) {
      super(props);
    }

    state = { 
        verifying: true,
        verified: false,
        verifiedEmailAddress: null
    }

    componentWillMount() {
        const { onVerifyEmailAddress } = this.props;
        onVerifyEmailAddress()
            .then((verifiedEmailAddress) => {
                this.setState({ 
                    verifying: false,
                    verified: true,
                    verifiedEmailAddress: verifiedEmailAddress
                });
            })
            .catch((error) => {
                this.setState({ 
                    verifying: false,
                    verified: false,
                    verifiedEmailAddress: null
                });
            })
    }

    render() {
        const { verifying, verified, verifiedEmailAddress } = this.state;

        if(verifying) {
            return <Loading message='Verifying email address'/>
        } else if(verified) {
            return <EmailVerified emailAddress={verifiedEmailAddress}/>
        } else {
            return <EmailVerificationFailed/>
        }
    }
}

const EmailVerified = (props) => {
    return (
        <Message
            success
            icon='checkmark'
            header='Email verification successful!'
            content={<p>We've verified your email address as <strong>{props.emailAddress}</strong>. Please <Link to="/login">click here</Link> to login</p>}
        />
    );
}

const EmailVerificationFailed = () => {
    return (
        <Message
            error
            icon='frown'
            header='Email verification failed!'
            content={<p>The email verification code is invalid or has expired. Please contact us if you want us to send you a new code</p>}
        />
    );
}

const Loading = (props) => {
  return (
    <Loader active inline='centered' size='large'>{props.message}</Loader>
  )
}

export default VerifyEmailAddress;
