import React, {Component} from 'react'
import { connect } from 'react-redux'
import {Button, Dropdown, Header, Loader, Menu, Segment, Container, Grid} from 'semantic-ui-react'
import {Route, Switch, withRouter} from 'react-router-dom'
import PropTypes from 'prop-types'
import {bindActionCreators} from 'redux'
import {login, logout} from '../../modules/auth'



class NewHeader extends React.Component {

  static contextTypes = {
    router: PropTypes.shape({
      history: PropTypes.shape({
        push: PropTypes.func.isRequired,
        replace: PropTypes.func.isRequired
      }).isRequired,
      staticContext: PropTypes.object
    }).isRequired
  }

  render() {

    const { history } = this.context.router;

    const newHomeLink = () => history.push('/');
    const newDashboardLink = () => history.push('/dashboard');
    const newloginLink = () => history.push('/login');
    const newsignupLink = () => history.push('/signup');

    const unauthenticatedMenu = (
      <Menu.Menu position="right">
        <Menu.Item className="log-in-button" onClick={newloginLink}>Log in</Menu.Item>
        <Menu.Item className="sign-up-button" fitted>
          <Button color='green' onClick={newsignupLink}>Sign up</Button>
        </Menu.Item>
      </Menu.Menu>
    );

    const authenticatedMenu = () => (
      <Menu.Menu position="right">
        <Menu.Item className="log-out-button" name="logout" onClick={this.props.logout}>Logout</Menu.Item>
        <Dropdown item icon="content">
            <Dropdown.Menu>
              <Dropdown.Item onClick={newDashboardLink}>My dashboard</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
      </Menu.Menu>
    );

        return (
          <Menu size="massive" borderless color="blue" inverted attached='top'>
          <Menu.Item header onClick={newHomeLink} style={{paddingRight:"unset"}}>
          <div>
            <img className="primary-logo" src="images/hozah-autopay-64.png" alt="Hozah Autopay"/>
          </div>
          </Menu.Item>
          {this.props.isAuthenticated ? authenticatedMenu() : unauthenticatedMenu}
        </Menu>
          );
      };
    }


    const mapStateToProps = state => ({
      isAuthenticated: state.auth.isAuthenticated,
      initialising: state.auth.initialising
    })
    
    const mapDispatchToProps = dispatch => bindActionCreators({
      login,
      logout
    }, dispatch)
    
export default connect(
  mapStateToProps,
  mapDispatchToProps
  )(NewHeader)


