import { CALL_API } from 'redux-api-middleware';

export const FETCH_TRANSACTION_HISTORY = 'fullTransactionHistory/FETCH_TRANSACTION_HISTORY'
export const FETCHED_TRANSACTION_HISTORY = 'fullTransactionHistory/FETCHED_TRANSACTION_HISTORY'
export const FAILED_TO_FETCH_TRANSACTION_HISTORY = 'fullTransactionHistory/FAILED_TO_FETCH_TRANSACTION_HISTORY'
export const FETCH_MORE_TRANSACTIONS = 'fullTransactionHistory/FETCH_MORE_TRANSACTIONS'
export const FETCHED_MORE_TRANSACTIONS = 'fullTransactionHistory/FETCHED_MORE_TRANSACTIONS'
export const FAILED_TO_FETCH_MORE_TRANSACTIONS = 'fullTransactionHistory/FAILED_TO_FETCH_MORE_TRANSACTIONS'

export const onFetchTransactionHistory = () => {
    return dispatch => {
        dispatch({
            [CALL_API]: {
                endpoint: '/transactions/all?pageSize=5&pageNumber=1',
                method: 'GET',
                types: [FETCH_TRANSACTION_HISTORY, FETCHED_TRANSACTION_HISTORY, FAILED_TO_FETCH_TRANSACTION_HISTORY]
            }
        });
    }
}

export const onFetchMoreTransactions = (nextPageNumber) => {
    return dispatch => {
        dispatch({
            [CALL_API]: {
                endpoint: `/transactions/all?pageSize=5&pageNumber=${nextPageNumber}`,
                method: 'GET',
                types: [FETCH_MORE_TRANSACTIONS, FETCHED_MORE_TRANSACTIONS, FAILED_TO_FETCH_MORE_TRANSACTIONS]
            }
        });
    }
}

const initialState = {
  transactions: [],
  currentPage: 1,
  hasMorePages: false,
  fetching: true,
  fetchError: null,
  fetchingMoreTransactions: false,
  fetchMoreTransactionsError: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TRANSACTION_HISTORY:
      return {
        ...state,
        transactions: [],
        currentPage: 1,
        hasMorePages: false,
        fetching: true,
        fetchError: null,
        fetchingMoreTransactions: false,
        fetchMoreTransactionsError: null
      };
    case FETCHED_TRANSACTION_HISTORY:
      return {
        ...state,
        transactions: action.payload.transactions ? action.payload.transactions : [],
        currentPage: 1,
        hasMorePages: action.payload.hasMorePages,
        fetching: false,
        fetchError: null
      };
    case FAILED_TO_FETCH_TRANSACTION_HISTORY:
      return {
        ...state,
        fetching: false,
        fetchError: action.payload.statusText
      };
    case FETCH_MORE_TRANSACTIONS:
      return {
        ...state,
        fetchingMoreTransactions: true,
        fetchMoreTransactionsError: null
      };
    case FETCHED_MORE_TRANSACTIONS:
      return {
        ...state,
        transactions: [
          ...state.transactions,
          ...action.payload.transactions
        ],
        currentPage: state.currentPage + 1,
        hasMorePages: action.payload.hasMorePages,
        fetchingMoreTransactions: false,
        fetchMoreTransactionsError: null
      };
    case FAILED_TO_FETCH_MORE_TRANSACTIONS:
      return {
        ...state,
        fetchingMoreTransactions: false,
        fetchMoreTransactionsError: action.payload.statusText
      };
    default:
      return state;
  }
}