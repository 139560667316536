import { CALL_API } from 'redux-api-middleware';
import * as firebase from "firebase";
import firebaseConfig from '../config/FirebaseConfig'
import hozahConfig from '../config/HozahConfig'

export const INITIALISE_AUTH = 'auth/INITIALISE_AUTH'
export const AUTH_INITIALISED = 'auth/AUTH_INITIALISED'
export const LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS'
export const LOGOUT_REQUEST = 'auth/LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'auth/LOGOUT_SUCCESS'
export const LOGOUT_FAILURE = 'auth/LOGOUT_FAILURE'

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();

function json(response) {  
    var json = response.json(); 
    if (response.status >= 200 && response.status < 300) {  
        return json;
    } else {  
        return json.then(errorJson => {
            throw errorJson.error_code;
        });
    }  
}

class Auth {

    init(dispatch) {

        this._loginCallback = () => {}

        dispatch({
            type: INITIALISE_AUTH
        })

        var config = {
            apiKey: firebaseConfig.apiKey(),
            authDomain: firebaseConfig.authDomain(),
            databaseURL: firebaseConfig.databaseURL()
        };

        firebase.initializeApp(config);

        firebase.auth().getRedirectResult().then(function(result) {
            result.user.getIdToken(true).then( (token) =>{
                localStorage.setItem('id_token', token);
                dispatch({
                    type: LOGIN_SUCCESS
                });
            })
        }).catch(function(error) {
            dispatch({
                type: AUTH_INITIALISED
            })
        });

        firebase.auth().onAuthStateChanged( (user) => {
            if (user) {
                user.getIdToken(true).then( (token) =>{
                    localStorage.setItem('id_token', token);
                    this._loginCallback();
                    this._loginCallback = () => {};
                    dispatch({
                        type: LOGIN_SUCCESS
                    });
                })
            } else {
                clearAuthFromLocalStorage();
                dispatch({
                    type: LOGOUT_SUCCESS
                });
            }
        });
    }

    setLoginCallback(cb) {
        this._loginCallback = cb;
    }

    login(username, password) {
        return new Promise((resolve, reject) => {
             firebase.auth().signInWithEmailAndPassword(username, password)
             .then( () => {
                 resolve({});
             })
             .catch( (error) => {
                if (error.code == 'auth/user-not-found') {
                    var errorMessage = 'Sorry, we do not seem to have a user with that login. Please check and try again.'
                } else if (error.code == 'auth/wrong-password') {
                    var errorMessage = 'Sorry, that appears to be an invalid password. Please check and try again.'
                } else {
                    var errorMessage = error.message;
                }
                var errorCode = error.code;
                reject(errorMessage);
            });   
        });
    }
    
    loginWithGoogle() {
        firebase.auth().signInWithRedirect(googleAuthProvider);
    }

    loginWithFacebook() {
        firebase.auth().signInWithRedirect(facebookAuthProvider);
    }

    verifyEmailAddress(actionCode) {
        return new Promise((resolve, reject) => {
            var verifiedEmail = null;
            return firebase.auth().checkActionCode(actionCode)
                .then((info) => {
                    verifiedEmail = info['data']['email'];
                    return firebase.auth().applyActionCode(actionCode)
                })
                .then((response) => {
                    resolve(verifiedEmail);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    fetchMyDetails() {
           console.log("CALL API is " + hozahConfig.apiUri())
           var token = localStorage.getItem('id_token');
           return fetch(hozahConfig.apiUri() + '/me', {headers: {"X-HOZAH-ID-TOKEN": token}})
             .then(
               function(response) {
                 if (response.status !== 200) {
                   console.log('Looks like there was a problem. Status Code: ' + response.status);
                   return Promise.reject(response.status);
                 }
                 return Promise.resolve(response.json());
               }
             )
             .catch((err) => {
               console.log('Error fetching My details: ', err);
               return Promise.reject(err)
             });
        }

    // testCheckActionCode(actionCode) {
    //     return new Promise((resolve, reject) => {
    //         resolve({
    //             data: {
    //                 email: "joe.bloggs@gmail.com"
    //             }
    //         })
    //     });
    // }

    // testCheckActionCode(actionCode) {
    //     return new Promise((resolve, reject) => {
    //         resolve({
    //             data: {
    //                 email: "joe.bloggs@gmail.com"
    //             }
    //         })
    //     });
    // }

    // testVerifyPasswordResetCode(actionCode) {
    //     return new Promise((resolve, reject) => {
    //         resolve({});
    //     });
    // }  

    // testResetPassword(actionCode, newPassword) {
    //     return new Promise((resolve, reject) => {
    //         reject({});
    //     });
    // }  

    recoverEmailAddress(actionCode) {
        return new Promise((resolve, reject) => {
            var restoredEmail = null;
            return firebase.auth().checkActionCode(actionCode)
                .then((info) => {
                    restoredEmail = info['data']['email'];
                    return firebase.auth().applyActionCode(actionCode);
                })
                .then(() => {
                    resolve(restoredEmail);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    logout() {
        return firebase.auth().signOut();
    }

    triggerResetPassword(emailAddress) {
        return new Promise((resolve, reject) => {
            firebase.auth().sendPasswordResetEmail(emailAddress).then(() => {
                resolve({});
            }).catch((error) => {
                reject(error);
            });
        });
    }

    verifyPasswordResetCode(actionCode) {
        return new Promise((resolve, reject) => {
            firebase.auth().verifyPasswordResetCode(actionCode).then(() => {
                resolve({});
            }).catch((error) => {
                reject(error);
            });
        });
    }

    resetPassword(actionCode, newPassword) {
        return new Promise((resolve, reject) => {
            firebase.auth().confirmPasswordReset(actionCode, newPassword).then(() => {
                resolve({});
            }).catch((error) => {
                reject(error);
            });
        });
    }

    signup(emailAddress, password, firstName, lastName) {
        return new Promise((resolve, reject) => {
            firebase.auth().createUserWithEmailAndPassword(emailAddress, password)
                .then((user) => {
                    user.updateProfile({
                        displayName: firstName + ' ' + lastName
                    }).catch(function(error) {
                       console.log(error);
                    });
                    resolve({});
                })
                .catch((error) => {
                    var errorCode = error.code;
                    var errorMessage = error.message;
                    reject(errorMessage);
                });
        });
    }
}

const auth = new Auth();

export const init = (dispatch) => {
    auth.init(dispatch);
}
 
export const loginWithGoogle = () => {
    return auth.loginWithGoogle();
}

export const loginWithFacebook= () => {
    return auth.loginWithFacebook();
}

export const setLoginCallback = (cb) => {
    return auth.setLoginCallback(cb);
}

export const login = (emailAddress, password) => {
    return auth.login(emailAddress, password);
}

export const signup = (emailAddress, password, firstName, lastName) => {
    return auth.signup(emailAddress, password, firstName, lastName);
}

export const verifyEmailAddress = (actionCode) => {
    return () => auth.verifyEmailAddress(actionCode);
}

export const fetchMyDetails = () => {
    return auth.fetchMyDetails();
}

export const recoverEmailAddress = (actionCode) => {
    return () => auth.recoverEmailAddress(actionCode);
}

export const triggerResetPassword = (emailAddress) => {
    return auth.triggerResetPassword(emailAddress);
}

export const verifyPasswordResetCode = (actionCode) => {
    return () => auth.verifyPasswordResetCode(actionCode);
}

export const resetPassword = (actionCode) => {
    return (newPassword) => auth.resetPassword(actionCode, newPassword);
}

export const logout = () => {
    return dispatch => {
        dispatch({
            type: LOGOUT_REQUEST
        });
        auth.logout();
    }
}

export const clearAuthFromLocalStorage = () => {
    localStorage.removeItem('id_token');
}

// *** REDUCER ***

function validCredentialsInLocalStorage() {
    // TODO: Check JWT expiry
    return localStorage.getItem('id_token');
}

const initialState = {
  initialising: true,
  isAuthenticated: validCredentialsInLocalStorage() ? true : false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case INITIALISE_AUTH:
      return {
          ...state,
          initialising: true
      }
    case AUTH_INITIALISED:
      return {
          ...state,
          initialising: false
      }
    case LOGIN_SUCCESS:
      return {
        ...state,
        initialising: false,
        isAuthenticated: true,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        isAuthenticated: false
      };
    default:
      return state;
  }
}