import React, {Component} from 'react'
import PropTypes from 'prop-types'
import { Segment, Header, List, Icon, Card, Label } from 'semantic-ui-react';

const NotFound = () => (
     <Segment secondary >
        <Label pointing>
               <p> <Icon name='frown' /> We couldn't find details for that registration number </p>
               <p> Have you typed it correctly? </p>
            </Label>
     </Segment>
)

const LookupError = (props) => (
    <Segment secondary >
    <Label pointing>
           <p> <Icon name='frown' />We had a problem looking up your registration number </p>
           <p> Please try again </p>
        </Label>
 </Segment>
)

class VehicleDetails extends Component {

    render() {
        const { data, notFound, error } = this.props;
        
        if(notFound) {
            return <NotFound/>
        } else if(error) {
            return <LookupError/>
        } else {
            return (
                <div className="vehicle-information">
                    <Label pointing>
                    <h3>Vehicle information</h3>
                    <Card.Content centered className="card-color">
                        <Card.Header><strong className="h4"><img src="images/car-icon.svg" width="20" alt="" className="car-icon" />{data.vrm}</strong></Card.Header>
                        <Card.Description><p>{data.make}, {data.colour}, {data.fuelType}, {data.yearOfManufacture}</p></Card.Description>
                    </Card.Content>
                    </Label>
                    </div>
            );
        }
    }
}

VehicleDetails.propTypes = {
    data: PropTypes.shape({
        make: PropTypes.string.isRequired,
        colour: PropTypes.string.isRequired,
        fuelType: PropTypes.string.isRequired,
        yearOfManufacture: PropTypes.number.isRequired
    }),
    notFound: PropTypes.bool,
    error: PropTypes.string
};

export default VehicleDetails;