import React from 'react';
import {Route, Switch, withRouter} from 'react-router-dom'
import PropTypes from 'prop-types'
import Home from '../home'
import Dashboard from '../old/dashboard'
import NewDashboard from '../dashboard'
import BUDashboard from '../buDashboard'
import withCssSelector from '../../components/css/cssSelector'
import OldAddVehicle from '../old/addVehicle'
import ActiveSession from '../activeSessions'
import TransactionHistory from '../transactionHistory'
import FirebaseCallback from '../firebaseCallback'
import AuthorizedRoute from '../auth'
import Login from '../login'
import ForgotPassword from '../forgotPassword'
import Signup from '../old/signup'
import NewSignup from '../signup'
import BUSignup from '../buSignup'
import Footer from '../old/footer'
import NewFooter from '../footer'
import Welcome from '../welcome'
import BuThankYou from '../welcome/BuThankYou'
import PaymentAuth from '../paymentAuth'
import {Button, Dropdown, Header, Loader, Menu, Segment, Container, Grid} from 'semantic-ui-react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {login, logout} from '../../modules/auth'
import BULandingPage from '../bournemouthUnviversity'
import BuHeader from '../headers/buHeader';
import NewHeader from '../headers/newHeader';
import OldHeader from '../headers/header';
import BuFooter from '../footer/buFooter';




const PageHeader = (props) => (
    <Header attached as='h2' block color='blue' textAlign='center'>
      {props.title}
    </Header>
);

class App extends React.Component {

  static contextTypes = {
    router: PropTypes.shape({
      history: PropTypes.shape({
        push: PropTypes.func.isRequired,
        replace: PropTypes.func.isRequired
      }).isRequired,
      staticContext: PropTypes.object
    }).isRequired
  }

  render() {

      const { location } = this.props
      let footer = null
      let header = null
      let wrapper = null

      if (window.location.href.indexOf("bournemouth-university") != -1) {
        footer = <BuFooter />
        header = <BuHeader />
      } else {
        footer = <NewFooter />
        header = <NewHeader />
      }

      if(this.props.initialising) {
        return <Segment basic><Loader active inline='centered' /></Segment>
      }


    return (
      <div>
        <div>
          {header}
        <Route path="/dashboard/vehicles/add" component={() => <PageHeader title='Add a vehicle'/>}/>
        <Route path="/dashboard/active-sessions" component={() => <PageHeader title='Active sessions'/>} />
        <Route path="/dashboard/parking-invoice-history" component={() => <PageHeader title='Parking invoice history'/>} />
        </div>
        <div className={wrapper}>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/bournemouth-university" component={BULandingPage} />
            <Route exact path="/signup" component={NewSignup} />
            <Route exact path="/bournemouth-university/signup" component={BUSignup} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route exact path='/auth-callback' component={FirebaseCallback}/>
            <AuthorizedRoute path="/paymentauth/:id" component={PaymentAuth}/>
            <AuthorizedRoute exact path="/dashboard" component={NewDashboard} />
            <AuthorizedRoute exact path="/bournemouth-university/dashboard" component={BUDashboard} />
            <AuthorizedRoute exact path="/dashboard/vehicles/add" component={OldAddVehicle} />
            <AuthorizedRoute exact path="/dashboard/active-sessions/:id" component={ActiveSession} />
            <AuthorizedRoute exact path="/dashboard/parking-invoice-history" component={TransactionHistory} />
            <AuthorizedRoute exact path="/welcome" component={Welcome}/>
            <AuthorizedRoute exact path="/bournemouth-university/welcome" component={BuThankYou}/>

          </Switch>
          </div>
          {/* new or old footer */}
        {footer}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  initialising: state.auth.initialising
})

const mapDispatchToProps = dispatch => bindActionCreators({
  login,
  logout
}, dispatch)

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withCssSelector(App)))
