import { logout } from '../modules/auth'

export default store => next => action => {
    if (action.payload && action.payload.name === 'ApiError') {
        if (action.payload.status === 403 || action.payload.status === 401) {
            // TODO: need to pass relevant error message so that login page can show why user has been redirected
            dispatch(logout(), store);
        } else {
           return next(action);
        }
    } else {
        return next(action)
    }
    // TODO: Handle 500 server error here by sending to generic error page?
    // TODO: Send all unexpected errors to BugSnag (or similar)
}

const dispatch = (fn, store) => {
    fn(action => store.dispatch(action))
}
