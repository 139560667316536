import React, { Component } from "react";


const withCssSelector = (WrapComponent) => {
  
  var ss = document.createElement("link");
  ss.type = "text/css";
  ss.rel = "stylesheet";

  if (window.location.href.indexOf("bournemouth-university") != -1) {
    ss.href = "/css/BuSignUp.css";
    document.getElementsByTagName("head")[0].appendChild(ss);
  } else {
    ss.href = "/css/NewSignUp.css";
    document.getElementsByTagName("head")[0].appendChild(ss);
  }
  
  return class extends Component {

    render(){
        return (<WrapComponent {...this.props}></WrapComponent>)
    }
  };
};

export default withCssSelector;