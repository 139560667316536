import React, {Component} from 'react'
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Input, Menu, Segment, Container, Header, Label, Icon, Modal, Button } from 'semantic-ui-react';

const noBorderStyle ={ 'borderStyle': 'none' };

class AssignedPermit extends Component {

  state = { open: false }

  handleShow = () => this.setState({ open: true })
  handleNo = () => this.setState({ open: false })
  handleYes = () => {
      this.setState({ open: false });
      this.props.onDelete(this.props.id);
  }

  render() {
    const { open } = this.state;
    const { id, name } = this.props;
    return (
        <div>
            <Label>
                {name} <Icon name='delete' onClick={this.handleShow}/>
            </Label>
            <Modal open={open} basic size='small'>
                <Header icon='archive' content={name} style={noBorderStyle}/>
                <Modal.Content >
                    <h4>Are you sure you want to remove this permit?</h4>
                </Modal.Content>
                <Modal.Actions style={noBorderStyle}>
                    <Button.Group widths={2}>
                    <Button color='grey' onClick={this.handleNo}>No</Button>
                    <Button color='green' onClick={this.handleYes}>Yes</Button>
                    </Button.Group>
                </Modal.Actions>
            </Modal>
        </div>
    );
  }
}

class AssignedPermits extends Component {

  render() {
    var { permits, onDeletePermit } = this.props
    console.log(permits)
    return (
        <Segment basic>
            <Header as='h5'>Existing permits</Header>
            {permits.map((permit, i) =>
                <AssignedPermit key={permit.id} id={permit.id} name={permit.name} onDelete={onDeletePermit}/>
            )}
        </Segment>
    );
  }
}

AssignedPermits.propTypes = {
    permits: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired
        }).isRequired
    ).isRequired,
    onDeletePermit: PropTypes.func.isRequired
};

export default AssignedPermits