import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
import createHistory from 'history/createBrowserHistory';
import { apiMiddleware } from 'redux-api-middleware';
import frontApiInjector from './middleware/FrontApiInjector';
import frontApiErrors from './middleware/FrontApiErrors';
import rootReducer from './modules';

export const history = createHistory();

const initialState = {}
const enhancers = []
const middleware = [ frontApiInjector, apiMiddleware, frontApiErrors, thunk, routerMiddleware(history) ]

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.devToolsExtension;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
)

const store = createStore(
  rootReducer,
  initialState,
  composedEnhancers
)

export default store
