import React, {Component} from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Segment, Divider, Loader, Header, Icon, Message } from 'semantic-ui-react'
import ParkingInvoice from '../../../components/transactionHistory/ParkingInvoice'
import { onFetchTransactionHistorySummary } from '../../../modules/transactionHistory/transactionHistorySummary'
import { Link } from 'react-router-dom'

class TransactionHistorySummary extends Component {

    componentWillMount() {
        this.props.onFetchTransactionHistorySummary();
    }

    render() {
        const { transactions, fetching, fetchError, onFetchTransactionHistorySummary } = this.props;
        if (fetching) {
            return (<Loading {... this.props}/>);
        } else if (fetchError) {
            return (
                <FetchError onTryAgain={onFetchTransactionHistorySummary}/>
            );
        } else {
            if(!transactions || transactions.length === 0) {
                return <NoTransactions/>
            } else {
                return (
                    <div>
                        <Divider/>
                        {transactions.map((transaction, i) => 
                            <ParkingInvoice key={transaction.id} transaction={transaction}/>
                        )}
                        {transactions.length >= 5 ? <Segment basic textAlign='center'>
                            <p><Link to="/dashboard/parking-invoice-history">See full history of parking invoices</Link></p>
                        </Segment>: null}
                    </div>
                );
            }
        }
    }
}

const NoTransactions = () => {
    return (
        <Segment disabled secondary textAlign='center'>
            <Header as='h4' icon>
                <Icon name='history' color='grey' />
                    You have no previous transactions
            </Header>
        </Segment>
    );
}

const FetchError = (props) => {
    return (
        <Segment disabled secondary textAlign='center'>
            <Header as='h4' icon>
                <Icon name='frown' />
                    Failed to load parking invoice history
                <Header.Subheader>
                    Please&nbsp;<a href='#' onClick={(e) => {e.preventDefault(); props.onTryAgain(); }}>click here</a>&nbsp;to try again.
                </Header.Subheader>
            </Header>
        </Segment>
    );
}

const Loading = (props) => {
  return (
    <Loader active inline='centered' size='small'>Loading transactions</Loader>
  )
}

const mapStateToProps = state => ({
      transactions: state.transactionHistorySummary.transactions,
      fetching: state.transactionHistorySummary.fetching,
      fetchError: state.transactionHistorySummary.fetchError
})

const mapDispatchToProps = dispatch => bindActionCreators({
    onFetchTransactionHistorySummary
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransactionHistorySummary)
