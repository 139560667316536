import React, {Component} from 'react'
import PropTypes from 'prop-types'
import { Button, Segment } from 'semantic-ui-react'

class ConfirmVehicle extends Component {
  
  render() {
    const { confirming, onConfirm, onCancel } = this.props;
    return [
        <Segment secondary>
        <p className="bu-fonts">Hozah will manage payment for any current or future parking in all Hozah-enabled car parks.</p>
        <Button.Group  key={1} widths={2} size='large'>
            <Button id="confirm-vehicle-new" className="bu-vehicle-continue-button" onClick={() => onConfirm()} disabled={confirming} loading={confirming}>Continue</Button>
        </Button.Group>
        <div className="not-my-vehicle"><a id="not-my-vehicle-new" onClick={() => onCancel()} disabled={confirming}>This is not my vehicle</a></div>
        <br/>
        <br/>
        </Segment>
    ];
  }
}

ConfirmVehicle.propTypes = {
  confirming: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default ConfirmVehicle