import React, {Component} from 'react'
import { Segment, Grid, Container } from 'semantic-ui-react'
import SignupForm from '../../components/auth/SignupForm'
import { signup, LOGIN_SUCCESS, setLoginCallback } from '../../modules/auth'
import { SubmissionError } from 'redux-form'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { loginWithGoogle, loginWithFacebook } from '../../modules/auth'
import { push } from 'react-router-redux'
import { bindActionCreators } from 'redux'
import queryString from 'query-string'
import { onAddNewPermit } from '../../modules/permits/permits'
import * as firebase from "firebase";

const onSignupWithFacebook = () => {
  return dispatch => {
    dispatch(push('/login'));
    loginWithFacebook();
  }
}

const doSignup = (values) => { return dispatch => {
    if (values.creweConsent === "yes") {
        setLoginCallback( () => dispatch(onAddNewPermit('CREWE1')) )
    }
    return signup(values.emailAddress, values.password, values.firstName, values.lastName)
        .catch(err => {
            // TODO: Check for specific error code type (e.g. email not verified) and show more helpful error message
            throw new SubmissionError({
                _error: err
            })
        })
    }
}



class Signup extends Component {

    constructor(props) {
        super(props);
        const queryParameters = queryString.parse(window.location.search);
        const crewe = (queryParameters.promo === 'crewe')
        this.state = {
            hasCrewePromo: crewe
        };
    }

    render() {
        return (
          <div>
              <div className="tagline">
            <Container>
            <Grid  stackable textAlign='left'>
            <Grid.Row columns={1}>
            <Grid.Column>
              <h1>Sign up to Hozah</h1>
              <p>It’s fast, secure and you won’t be charged until your first eligible parking session.</p>             
            </Grid.Column>
            </Grid.Row>
            </Grid>
            </Container>
            </div>

            <div className="section">
            <Container>
            <Grid stackable textAlign='left'>
                    <Grid.Row columns={2}>
            <Grid.Column width={10}>
                <div id="w-node-6f2801999db4-7c09cd64" 
                    className="progress-block-new progress-block-active-new">
                    <div className="progress-number-new">1</div>
                    <div className="progress-title-new h2">Tell us about you</div>
                </div>
            <SignupForm 
              onSubmit={this.props.doSignup} 
              onFacebookSignup={this.props.onSignupWithFacebook} 
              hasCrewePromo={this.state.hasCrewePromo}
              redirectTo={"/dashboard"}
            />  
                <div className="steps-divider"></div>
                <div id="w-node-6f2801999db9-7c09cd64" className="progress-block-new progress-block-inactive">
                    <p className="progress-number-new">2</p>
                    <div className="progress-title-new h2">Find your vehicle</div>
                </div> 
                <div className="steps-divider"></div>
                <div id="w-node-6f2801999dbe-7c09cd64" className="progress-block-new progress-block-inactive">
                    <div className="progress-number-new">3</div>
                    <div className="progress-title-new h2">Enter payment details</div>
                </div>        
              </Grid.Column>
            <Grid.Column width={6}>
                <div className="sidebar">
                <div id="secure-payment">
                    <div className="icon-list-new">
                    <p className="h5"><strong>Secure payment by</strong></p>
                    <div>
                        <img src="images/payment-icons.png" />
                        </div>
                    </div>
                </div>
                <div id="why-use-hozah">
                    <div className="icon-list-new">
                    <p className="h5"><strong>Why Use Hozah Autopay?</strong></p>
                    <p className="small">One-time sign up for accurate, automatic payments whenever you visit a Hozah Autopay-enabled car park.</p>
                    <p className="small">Convenience fee between 0p - 20p applies per parking session.</p>
                        <div className="icon-list-item-new"><img src="images/Cash.png" width="64" alt="" className="icon" />
                            <p className="icon-description-new">No need to carry cash</p>
                        </div>
                        <div className="icon-list-item-new"><img src="images/Clock.png" width="64" alt="" className="icon" />
                            <p className="icon-description-new">No waiting at the ticket machine</p>
                        </div>
                        <div className="icon-list-item-new"><img src="images/Phone.png" width="64" alt="" className="icon" />
                            <p className="icon-description-new">No need to download an app</p>
                        </div>
                        <div className="icon-list-item-new"><img src="images/H-logo.png" width="64" alt="" className="icon" />
                            <p className="icon-description-new"><strong>Simply park and go</strong></p>
                        </div>
                    </div>
                </div>
                </div>
            </Grid.Column>
    </Grid.Row>
    </Grid>

    </Container>
    </div>
    </div>
        );
    }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
})

const mapDispatchToProps = dispatch => bindActionCreators({
  onSignupWithFacebook,
  doSignup
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Signup)