import React, {Component} from 'react'
import { Segment} from 'semantic-ui-react'
import SignupForm from '../../../components/auth/SignupForm'
import OldSignupForm from '../../../components/old/auth/SignupForm'
import { signup, LOGIN_SUCCESS, setLoginCallback } from '../../../modules/auth'
import { SubmissionError } from 'redux-form'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { loginWithGoogle, loginWithFacebook } from '../../../modules/auth'
import { push } from 'react-router-redux'
import { bindActionCreators } from 'redux'
import queryString from 'query-string'
import { onAddNewPermit } from '../../../modules/permits/permits'
import * as firebase from "firebase";

const onSignupWithFacebook = () => {
  return dispatch => {
    dispatch(push('/login'));
    loginWithFacebook();
  }
}

const doSignup = (values) => { return dispatch => {
    if (values.creweConsent === "yes") {
        setLoginCallback( () => dispatch(onAddNewPermit('CREWE1')) )
    }
    return signup(values.emailAddress, values.password, values.firstName, values.lastName)
        .catch(err => {
            // TODO: Check for specific error code type (e.g. email not verified) and show more helpful error message
            throw new SubmissionError({
                _error: err
            })
        })
    }
}

class Signup extends Component {

    constructor(props) {
        super(props);
        const queryParameters = queryString.parse(window.location.search);
        const crewe = (queryParameters.promo === 'crewe')
        this.state = {
            hasCrewePromo: crewe
        };
    }

    render() {
        return (
          <div>
              <div className="progress-section">
                  <div className="w-container">
                      <div className="form-container">
                          <div className="w-layout-grid grid-3">
                              <div id="w-node-6f2801999db4-7c09cd64"
                                 className="progress-block progress-block-active w-inline-block">
                                  <div className="progress-number">1</div>
                                  <div className="progress-title">About you</div>
                              </div>
                              <div id="w-node-6f2801999db9-7c09cd64" 
                                 className="progress-block w-inline-block">
                                  <div className="progress-number">2</div>
                                  <div className="progress-title">Vehicle</div>
                              </div>
                              <div id="w-node-6f2801999dbe-7c09cd64" 
                                 className="progress-block w-inline-block">
                                  <div className="progress-number">3</div>
                                  <div className="progress-title">Payment</div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
            <Segment basic textAlign='center'>
              <h2>Sign up for an account</h2>
            </Segment>
            <OldSignupForm 
              onSubmit={this.props.doSignup} 
              onFacebookSignup={this.props.onSignupWithFacebook} 
              hasCrewePromo={this.state.hasCrewePromo}
              redirectTo={"/dashboard"}
            />
          </div>
        );
    }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
})

const mapDispatchToProps = dispatch => bindActionCreators({
  onSignupWithFacebook,
  doSignup
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Signup)