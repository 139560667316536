import React, {Component} from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { Segment, Input, Form, Button, Message } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import LoginForm from '../../components/auth/LoginForm'
import { login } from '../../modules/auth'
import { SubmissionError } from 'redux-form'

class Login extends Component {

    doLogin = (values) => {
        return login(values.emailAddress, values.password)
          .catch( err => {
            // TODO: Check for specific error code type (e.g. email not verified) and show more helpful error message
            throw new SubmissionError({
              _error: err
            })
          });
    }

    render() {
        const { isAuthenticated } = this.props;

        // TODO: Should make a reusable higher order component around this condition?
        if(isAuthenticated) {
          return (<Redirect to="/dashboard" />);
        } else {
          return (
            <div>
              <LoginForm onSubmit={this.doLogin}/>
            </div>
          );
        }
    }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(
  mapStateToProps
)(Login)
