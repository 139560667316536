import React, {Component} from 'react'
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Segment, Grid, Icon, Divider, Header, Table} from 'semantic-ui-react';

const attributeHeaderStyle = {
    'display': 'block',
    'color': 'grey',
    'margin': '0 0 .28rem 0',
    'textTransform': 'none',
    'fontWeight': '700',
    'fontSize': '.93em'
}

const attributeValueStyle = {
    'fontSize': '1em'
}

const tableStyleHeader = {
    'border': 'none'
}

const gridStyle = {
    'marginBottom': '-1rem'
}

class TransactionHeader extends Component {

    render() {
        const { transaction } = this.props;
        return (
            <Grid style={gridStyle} columns={2} verticalAlign='middle'>
                <Grid.Row>
                    <Grid.Column width={14} textAlign='left'>
                        <Table style={tableStyleHeader} compact basic fixed singleLine unstackable>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell style={tableStyleHeader} width='6'>
                                        <Header style={attributeHeaderStyle} sub color='grey'>Vehicle</Header>
                                        <span style={attributeValueStyle}>{transaction.vrm}</span>
                                    </Table.Cell>
                                    <Table.Cell style={tableStyleHeader} width='6'>
                                        <Header style={attributeHeaderStyle} sub color='grey'>Date entered</Header>
                                        <span style={attributeValueStyle}>{transaction.date}</span>
                                    </Table.Cell>
                                    <Table.Cell style={tableStyleHeader} width='6'>
                                        <Header style={attributeHeaderStyle} sub color='grey'>Car park</Header>
                                        <span style={attributeValueStyle}>{transaction.carParkName}</span>
                                    </Table.Cell>
                                    <Table.Cell style={tableStyleHeader} width='4'>
                                        <Header style={attributeHeaderStyle} sub color='grey'>Cost</Header>
                                        <span style={attributeValueStyle}>{transaction.cost.currency}{(transaction.cost.amount / 100).toFixed(2)}</span>                
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

class ParkingInvoice extends Component {

    render() {
        return (
            <Segment vertical>
                <TransactionHeader {...this.props}/>
            </Segment>
        );
    }
}

ParkingInvoice.propTypes = {
    expanded: PropTypes.bool,
    transaction: PropTypes.shape({
        id: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        carParkName: PropTypes.string.isRequired,
        vrm: PropTypes.string.isRequired,
        paymentAccount: PropTypes.string.isRequired,
        cost: PropTypes.shape({
            currency: PropTypes.string.isRequired,
            amount: PropTypes.number.isRequired
        }).isRequired,
        timeIn: PropTypes.string.isRequired,
        timeOut: PropTypes.string.isRequired,
        totalTime: PropTypes.string.isRequired
    }).isRequired,
    onExpand: PropTypes.func.isRequired,
    onCollapse: PropTypes.func.isRequired
};

export default ParkingInvoice