import { CALL_API } from 'redux-api-middleware';

export const FETCH_ACTIVE_SESSION = 'activeSession/FETCH_TRANSACTION_SUMMARY'
export const FETCHED_ACTIVE_SESSION = 'activeSession/FETCHED_TRANSACTION_SUMMARY'
export const FAILED_TO_FETCH_ACTIVE_SESSION = 'activeSession/FAILED_TO_FETCH_TRANSACTION_HISTORY_SUMMARY'
export const REFRESH_ACTIVE_SESSION = 'activeSession/REFRESH_ACTIVE_SESSION'
export const FAILED_TO_REFRESH_ACTIVE_SESSION = 'activeSession/FAILED_TO_REFRESH_ACTIVE_SESSION'

export const onFetchActiveSession = (sessionId) => {
    return dispatch => {
        dispatch({
            [CALL_API]: {
                endpoint: `/active-sessions/${sessionId}`,
                method: 'GET',
                types: [FETCH_ACTIVE_SESSION, FETCHED_ACTIVE_SESSION, FAILED_TO_FETCH_ACTIVE_SESSION]
            }
        });
    }
}

export const onRefreshActiveSession = (sessionId) => {
    return dispatch => {
        dispatch({
            [CALL_API]: {
                endpoint: `/active-sessions/${sessionId}`,
                method: 'GET',
                types: [REFRESH_ACTIVE_SESSION, FETCHED_ACTIVE_SESSION, FAILED_TO_REFRESH_ACTIVE_SESSION]
            }
        });
    }
}

const initialState = {
  activeSession: null,
  fetching: true,
  fetchError: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ACTIVE_SESSION:
      return {
        ...state,
        activeSession: null,
        fetching: true,
        fetchError: null
      };
    case FETCHED_ACTIVE_SESSION:
      return {
        ...state,
        activeSession: action.payload,
        fetching: false,
        fetchError: null
      };
    case FAILED_TO_FETCH_ACTIVE_SESSION:
      return {
        ...state,
        fetching: false,
        fetchError: action.payload.statusText
      };
    default:
      return state;
  }
}