import React, {Component} from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Container, Grid, Button } from 'semantic-ui-react'
import { Redirect } from 'react-router'

class Welcome extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { initialising, initialised, initialisationError } = this.props;
        return (
            <Container>
            <div className="bu-header"> 
            <h2>Thank You</h2>
            </div>
            <Grid stackable textAlign='left'>
            <Grid.Row columns={2} className="content-container">
            <Grid.Column width={10}>

            <div className="bu-fonts">
            Your sign up was successful! We’ve created an account for you and your pilot parking permit is effective immediately. Happy parking!            
            </div>
            <div>
            <a href="/dashboard"> <Button className="bu-go-to-account-button">Go to my account</Button> </a>
            </div>
            </Grid.Column>
            </Grid.Row>
            </Grid>
            </Container>
            
        )
    }
}

export default Welcome;