import React, {Component} from 'react'
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Segment, Icon, Label, Button, Grid, Divider, Header } from 'semantic-ui-react';

const attributeHeaderStyle = {
    'display': 'block',
    'color': 'grey',
    'margin': '0 0 .28rem 0',
    'textTransform': 'none',
    'fontWeight': '700',
    'fontSize': '.93em'
}

class CardDetails extends Component {
  
  render() {
    const { card, onEdit, loading } = this.props;
    return (
        <Segment basic loading={loading}>
          <div>
            <Header style={attributeHeaderStyle} sub color='grey'>Card number</Header>
            {card ? ( card.processingUpdate ? <span>UPDATING</span> : <span>XXXX XXXX XXXX {card.lastFourDigits}</span> ) : <span>UPDATING</span> }
          </div>
          <Divider/>
          <div>
            <Header style={attributeHeaderStyle} sub color='grey'>Expiry</Header>
            {card ? ( card.processingUpdate ? <span>UPDATING</span> : <span>{card.expiryMonth < 10 ? `0${card.expiryMonth}` : card.expiryMonth}/{card.expiryYear}</span> ) : <span>MM/YYYY</span>}
          </div>
          <Divider hidden/>
          <Button key={2} fluid color='grey' onClick={onEdit}>Update details</Button>
        </Segment>
    );
  }
}

CardDetails.propTypes = {
    onEdit: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    card: PropTypes.shape({
        lastFourDigits: PropTypes.string.isRequired,
        expiryMonth: PropTypes.number.isRequired,
        expiryYear: PropTypes.number.isRequired,
        processingUpdate: PropTypes.bool
    }),
};

export default CardDetails