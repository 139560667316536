import React, {Component} from 'react'
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Segment, Icon, Label, Button, Grid } from 'semantic-ui-react';

const noBorderStyle ={ 'borderStyle': 'none' };

const clickable ={ 'display': 'block' };

const AddVehicleLink = (props) => {
    return ([
        <Segment key={0} secondary disabled attached textAlign='center'>
            <Link to='/dashboard/vehicles/add' style={clickable}>
                <Icon size='huge' name='add circle' color='grey'/>   
            </Link>
        </Segment>,
        <Segment key={1} attached disabled secondary attached='bottom' textAlign='center'>
            <strong>Add another vehicle</strong>
        </Segment>
    ]);
};

export default AddVehicleLink 