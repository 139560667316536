import React, {Component} from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Step, Icon, Grid, Segment, Container } from 'semantic-ui-react'
import AddVehicle from '../addVehicle'
import PaymentDetails from './PaymentDetails'
import addVehicle from '../../modules/vehicles/addVehicle'

class DriverSettingsWizard extends Component {

    render() {

        const { hasPaymentCard, vehicles } = this.props;
        const requiresVehicle = vehicles.length === 0;
        const requiresPaymentCard = !hasPaymentCard;

        if(requiresVehicle || requiresPaymentCard) {
            return (
        <div>
            <div className="tagline">
            <Container>
            <Grid  stackable textAlign='left'>
            <Grid.Row columns={1}>
            <Grid.Column>
              <h1>Sign up to Hozah Autopay</h1>
              <p>It’s fast, secure and you won’t be charged until your first eligible parking session.</p>             
            </Grid.Column>
            </Grid.Row>
            </Grid>
            </Container>
            </div>

            <div className="section">
            <Container>
            <Grid stackable textAlign='left'>
                    <Grid.Row columns={2}>
            <Grid.Column width={10}>
            
                    
                    <div>
                                    <div id="w-node-6f2801999db4-7c09cd64"
                                       className="progress-block-new progress-block-complete-new">
                                        <div className="progress-number-new">1</div>
                                        <div className="progress-title-new h2">Tell us about you</div>
                                    </div> <div className="steps-divider"></div>

                                    {requiresVehicle ?
                                        <div id="w-node-6f2801999db9-7c09cd64"
                                           className="progress-block-new progress-block-active-new">
                                            <div className="progress-number-new">2</div>
                                            <div className="progress-title-new h2">Find your vehicle</div>
                                        </div>
                                        :
                                        <div id="w-node-6f2801999db9-7c09cd64"
                                           className="progress-block-new progress-block-complete-new">
                                            <div className="progress-number-new">2</div>
                                            <div className="progress-title-new h2">Find your vehicle</div>
                                        </div> }
                                        {requiresVehicle ? <AddVehicle hideBackLink={true} /> : null }
                                        <div className="steps-divider"></div>
                                    {!requiresVehicle && requiresPaymentCard ?
                                        <div id="w-node-6f2801999dbe-7c09cd64" className="progress-block-new progress-block-active-new">
                                            <div className="progress-number-new">3</div>
                                            <div className="progress-title-new h2">Enter payment details</div>
                                        </div> :
                                        <div id="w-node-6f2801999dbe-7c09cd64" className="progress-block-new">
                                            <div className="progress-number-new">3</div>
                                            <div className="progress-title-new h2">Enter payment details</div>
                                    </div> }
                                    {requiresVehicle ? null : <PaymentDetails initialEntry="Yes" key={1}/>}

                                </div>
                    </Grid.Column>

                    <Grid.Column width={6}>
                <div className="sidebar">
                <div id="secure-payment">
                    <div className="icon-list-new">
                    <p className="h5"><strong>Secure payment by</strong></p>
                    <div>
                        <img src="images/payment-icons.png" />
                        </div>
                    </div>
                </div>
                <div id="why-use-hozah">
                    <div className="icon-list-new">
                    <p className="h5"><strong>Why Use Hozah Autopay?</strong></p>
                    <p className="small">One-time sign up for accurate, automatic payments whenever you visit a Hozah Autopay-enabled car park.</p>
                    <p className="small">Convenience fee between 0p-20p applies per parking session (varies by car park).</p>
                        <div className="icon-list-item-new"><img src="images/Cash.png" width="64" alt="" className="icon" />
                            <p className="icon-description-new">No need to carry cash</p>
                        </div>
                        <div className="icon-list-item-new"><img src="images/Clock.png" width="64" alt="" className="icon" />
                            <p className="icon-description-new">No waiting at the ticket machine</p>
                        </div>
                        <div className="icon-list-item-new"><img src="images/Phone.png" width="64" alt="" className="icon" />
                            <p className="icon-description-new">No need to download an app</p>
                        </div>
                        <div className="icon-list-item-new"><img src="images/H-logo.png" width="64" alt="" className="icon" />
                            <p className="icon-description-new"><strong>Simply park and go</strong></p>
                        </div>
                    </div>
                </div>
                </div>
            </Grid.Column>
            </Grid.Row>
            </Grid>
            </Container>
            </div>
                </div>
            )
        } else {
            return this.props.children;
        };
    }
}

const mapStateToProps = state => ({
    hasPaymentCard: state.dashboard.hasPaymentCard,
    vehicles: state.dashboard.vehicles
})

const mapDispatchToProps = dispatch => bindActionCreators({
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DriverSettingsWizard)
