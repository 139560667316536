import React, {Component} from 'react'
import PropTypes from 'prop-types';
import {onBuCardSetupSuccess} from '../../modules/payment/payment'
import { Segment, Button, Form, Message, Grid, Icon } from 'semantic-ui-react';
import { push } from "react-router-redux";
import Cookies from 'universal-cookie'


class UpdateCardDetails extends Component {

  constructor(props) {
      super(props);
  }

  state = {
      cardComplete: false,
      cvcComplete: false,
      expiryComplete: false,
  }

    dispatchToWelcome = (dispatch) => {
        dispatch(push('/bournemouth-university/welcome'))
    };

    dispatchToDashboard = (dispatch) => {
        dispatch(push('/dashboard'))
    };

    handleSubmitRedirect = (redirect) => (ev) => {
        ev.preventDefault();
        this.props.onSave();
        this.props.onBuCardSetupSuccess(redirect)
    };

    handleCardNumberChanged = (changeObject) => {
      this.setState({
        ...this.state,
        cardComplete: changeObject.complete
      });
    }

    handleCvcChanged = (changeObject) => {
      this.setState({
        ...this.state,
        cvcComplete: changeObject.complete
      });
    }

  handleExpiryChanged = (changeObject) => {
      this.setState({
        ...this.state,
        expiryComplete: changeObject.complete
      });
  }

  render() {
    const { creating, onSave, onCancel, error, initial } = this.props;
    const { cardComplete, cvcComplete, expiryComplete } = this.state;
    const complete = cardComplete && cvcComplete && expiryComplete;

    return (
        <Segment basic loading={creating}>
          <div className="payment-details-warning">

            <p className="bu-fonts"> <Icon name='warning' color='orange'/>
Don’t worry about filling these in, during the trial the parking fees will be paid by Hozah.</p>
            <p className="bu-fonts">Please click 'Create account' to continue.</p>
          </div>
            <br />
            {error ? <Message negative>{error}</Message> : null}
            <Form>
            <Grid columns='equal' stackable textAlign='left'>
            <Grid.Row columns={2}>
            <Grid.Column>
                <Form.Field >
                <label>Card number (all major cards accepted) *</label>
                <input readOnly id="card-number" placeholder={"**** **** **** 5465"}/>
                <p className="additional-help-text">No dashes or spaces needed</p>
                </Form.Field>
                </Grid.Column>
                <Grid.Column>
                <Form.Field>
                <label>Expiry date *</label>
                <input readOnly placeholder={"02 / 25"}/>
                </Form.Field>
                </Grid.Column>
                <Grid.Column width={8}>
                <Form.Field>
                <label>Security code *</label>
                <input readOnly placeholder={"***"}/>
                </Form.Field>
                </Grid.Column>
                </Grid.Row>
                </Grid> 
            </Form>
            <br />
            <Form className="form-new">
            <Grid columns='equal' stackable textAlign='left'>
            <Grid.Row columns={2}>
                <Grid.Column width={8}>
                    {initial ? <Button id="submit-payment-details-bu" className="bu-button" onClick={this.handleSubmitRedirect(this.dispatchToWelcome)}>Create account</Button> : <Button color='green' onClick={this.handleSubmitRedirect(this.dispatchToDashboard)} disabled={!complete}>Update your details</Button> }
                    {onCancel ? <Button color='grey' onClick={() => onCancel()}>Cancel</Button>: null}
            </Grid.Column>
            </Grid.Row>
            </Grid> 
            </Form>
        </Segment>
    );
  }
}

UpdateCardDetails.propTypes = {
    creating: PropTypes.bool,
    error: PropTypes.string,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    onCardSetupSuccess: PropTypes.func.isRequired,
    onBuCardSetupSuccess: PropTypes.func.isRequired,
    onCardSetupFailed: PropTypes.func.isRequired
    
};

export default UpdateCardDetails;