import React, {Component} from 'react'
import PropTypes from 'prop-types';
import {getSetupId} from '../../modules/payment/payment'
import { Segment, Button, Form, Message, Grid } from 'semantic-ui-react';
import { injectStripe, CardNumberElement, CardExpiryElement, CardCVCElement } from 'react-stripe-elements';
import { push } from "react-router-redux";

const stripeElementOptions = () => {
  return {
      'style' : {
          'invalid': {
              'color': "rgb(159, 58, 56)"
          }
      }
  };
};

class UpdateCardDetails extends Component {

  constructor(props) {
      super(props);
  }

  state = {
      cardComplete: false,
      cvcComplete: false,
      expiryComplete: false,
  }

    dispatchToWelcome = (dispatch) => {
        dispatch(push('/welcome'))
    };

    dispatchToDashboard = (dispatch) => {
        dispatch(push('/dashboard'))
    };

    handleSubmitRedirect = (redirect) => (ev) => {
        ev.preventDefault();
        this.props.onSave();
        getSetupId().then(setupIntent => {
            this.props.stripe.handleCardSetup( setupIntent.secret, {})
              .then(response => {
                  const error = response.error;
                  if(error) {
                      // TODO: do more with error message from Stripe
                      this.props.onCardSetupFailed(error);
                  } else {
                      this.props.onCardSetupSuccess(setupIntent, redirect);
                  }
              });
        });
    };

    handleCardNumberChanged = (changeObject) => {
      this.setState({
        ...this.state,
        cardComplete: changeObject.complete
      });
    }

    handleCvcChanged = (changeObject) => {
      this.setState({
        ...this.state,
        cvcComplete: changeObject.complete
      });
    }

  handleExpiryChanged = (changeObject) => {
      this.setState({
        ...this.state,
        expiryComplete: changeObject.complete
      });
  }

  render() {
    const { creating, onSave, onCancel, error, initial } = this.props;
    const { cardComplete, cvcComplete, expiryComplete } = this.state;
    const complete = cardComplete && cvcComplete && expiryComplete;

    return (
      <div id="payment-section-new" className="ui form form-blank" >
        <Segment secondary loading={creating}>
            {error ? <Message negative>{error}</Message> : null}
            <Form>
            <Grid columns='equal' stackable textAlign='left'>
            <Grid.Row columns={1}>
            <Grid.Column>
                <Form.Field >
                <label>Card number (all major cards accepted) *</label>
                <CardNumberElement id="card-number" placeholder={"e.g. 1234 0000 0000 0000"} onChange={this.handleCardNumberChanged.bind(this)} {...stripeElementOptions()}/>
                <p className="additional-help-text">No dashes or spaces needed</p>
                </Form.Field>
                </Grid.Column>
                <Grid.Column width={8}>
                <Form.Field>
                <label>Expiry date *</label>
                <CardExpiryElement placeholder={"MM / YY"} onChange={this.handleExpiryChanged.bind(this)} {...stripeElementOptions()}/>
                </Form.Field>
                </Grid.Column>
                <Grid.Column width={8}>
                <Form.Field>
                <label>Security code *</label>
                <CardCVCElement placeholder={"e.g. 123"} onChange={this.handleCvcChanged.bind(this)} {...stripeElementOptions()}/>
                </Form.Field>
                </Grid.Column>

                <Grid.Column width={4} className="d-none">
                <Form.Field >

                <label><a className="whats-this-label">What's this?</a></label>
                <img textAlign='right' src="images/card.png" alt="card icon" width="64px" />
                </Form.Field>

                </Grid.Column>

                </Grid.Row>
                </Grid> 
            </Form>
            <br />
            <p>We'll save your details safely and securely, then take accurate payment automatically after you visit any Hozah Autopay car park. Convenience fee between 0p-20p applies per parking session (varies by car park).</p>
            <br />
            <Form className="form-blank">
            <Grid columns='equal' stackable textAlign='left'>
            <Grid.Row columns={2}>
                <Grid.Column width={8}>
                    {initial ? <Button id="submit-payment-details-new" color='green' onClick={this.handleSubmitRedirect(this.dispatchToWelcome)} disabled={!complete}>Create account</Button> : <Button color='green' onClick={this.handleSubmitRedirect(this.dispatchToDashboard)} disabled={!complete}>Update your details</Button> }
                    {onCancel ? <Button color='grey' onClick={() => onCancel()}>Cancel</Button>: null}
            </Grid.Column>
            </Grid.Row>
            </Grid> 
            </Form>
        </Segment>
        </div>
    );
  }
}

UpdateCardDetails.propTypes = {
    creating: PropTypes.bool,
    error: PropTypes.string,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    onCardSetupSuccess: PropTypes.func.isRequired,
    onCardSetupFailed: PropTypes.func.isRequired
};

export default injectStripe(UpdateCardDetails);