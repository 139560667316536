import React, {Component} from 'react'
import PropTypes from 'prop-types';
import { Segment, Input, Form, Button, Message, Label, Container, Divider, Icon, Grid} from 'semantic-ui-react'
import { get } from '../../modules/utils'
import { Field, reduxForm } from 'redux-form'
import { Link } from 'react-router-dom'
import { loginWithGoogle, loginWithFacebook } from '../../modules/auth'

const validate = values => {
    const errors = {}
    if (!values.emailAddress) {
        errors.emailAddress = 'You must enter your email address';
    } else if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(values.emailAddress)) {
        errors.emailAddress = 'Invalid email address';
    }
    if (!values.password) {
        errors.password = 'You must enter your password';
    }
    return errors;
}

const FormField = ({input, label, type, meta: { touched, error, warning }}) => (
    <Form.Field {... touched && error ? {error: true} : {}}>
      <input {...input} placeholder={label} type={type}/>
      {touched && error ? <Label basic color='red' pointing>{error}</Label>: null}
    </Form.Field>
)

const FormError = (error) => {
    if(error) {
        return (<Message {... error ? {error: true} : {}} header='Login failed' content={error}/>);
    } else {
        return null;
    }
}

class LoginForm extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            hasErrors: false
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.invalid) {
            this.setState({hasErrors: true});
        } else {
            this.setState({hasErrors: false});
        }
    }

    render() {
        const { error, handleSubmit, pristine, reset, submitting } = this.props
        const { hasErrors } = this.state
        return (  
            <div className='ui main text container'>
            <Segment secondary>
            <Segment basic>
                <h2>Log in to your account</h2>
                <Form {...hasErrors ? {error: true} : {error: false}} onSubmit={handleSubmit}>
                    {error ? FormError(error): null}
                    <Field name="emailAddress" type="text" label="Email address" component={FormField}/>
                    <Field name="password" type="password" label="Password" component={FormField}/>
                    <div className="w-row">
                        <div style={{position: "relative", float: "right"}}>
                            <Container fluid textAlign='left' style={{textDecoration: "underline"}}><Link to='/forgot-password'>Forgot your password?</Link></Container>
                        </div>
                        <div style={{position: "relative", float: "left"}}>
                            <Button color='green' size='large' type="submit" disabled={(hasErrors && !error) || pristine || submitting} loading={submitting}>Log in</Button>
                        </div>
                    </div>
                    </Form>
                </Segment>
            </Segment> 
            </div>
        );
    }
}

export default reduxForm({
  form: 'loginForm',
  validate
})(LoginForm)