import React, {Component} from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Step, Icon } from 'semantic-ui-react'
import AddVehicle from '../../old/addVehicle'
import PaymentDetails from './PaymentDetails'

class DriverSettingsWizard extends Component {
    render() {
        const { hasPaymentCard, vehicles } = this.props;
        const requiresVehicle = vehicles.length === 0;
        const requiresPaymentCard = !hasPaymentCard;

        if(requiresVehicle || requiresPaymentCard) {
            return (
                <div>
                    <div className="progress-section">
                        <div className="w-container">
                            <div className="form-container">
                                <div className="w-layout-grid grid-3">
                                    <div id="w-node-6f2801999db4-7c09cd64"
                                       className="progress-block progress-block-complete w-inline-block">
                                        <div className="progress-number">1</div>
                                        <div className="progress-title">About you</div>
                                    </div>
                                    {requiresVehicle ?
                                        <div id="w-node-6f2801999db9-7c09cd64" 
                                           className="progress-block progress-block-active w-inline-block w--current">
                                            <div className="progress-number">2</div>
                                            <div className="progress-title">Vehicle</div>
                                        </div> :
                                        <div id="w-node-6f2801999db9-7c09cd64"
                                           className="progress-block progress-block-complete w-inline-block w--current">
                                            <div className="progress-number">2</div>
                                            <div className="progress-title">Vehicle</div>
                                        </div> }
                                    {!requiresVehicle && requiresPaymentCard ?
                                        <div id="w-node-6f2801999dbe-7c09cd64"
                                           className="progress-block progress-block-active w-inline-block">
                                            <div className="progress-number">3</div>
                                            <div className="progress-title">Payment</div>
                                        </div> :
                                        <div id="w-node-6f2801999dbe-7c09cd64"
                                           className="progress-block w-inline-block">
                                            <div className="progress-number">3</div>
                                            <div className="progress-title">Payment</div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <br/>
                    <br/>
                    {requiresVehicle ? <AddVehicle hideBackLink={true}/> : [ <div> <h3 key={0}>3. Payment details</h3> </div>, <PaymentDetails initialEntry="Yes" key={1}/>]}
                </div>
            )
        } else {
            return this.props.children;
        };
    }
}

const mapStateToProps = state => ({
    hasPaymentCard: state.dashboard.hasPaymentCard,
    vehicles: state.dashboard.vehicles
})

const mapDispatchToProps = dispatch => bindActionCreators({
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DriverSettingsWizard)
