import React, {Component} from 'react'
import PropTypes from 'prop-types';
import { Card, Icon, Button, Segment, Grid, Label } from 'semantic-ui-react';
import Vehicle from './Vehicle';
import AddVehicleLink from './AddVehicleLink';
import NoVehicles from './NoVehicles';

const Vehicles = (props) => {
    var { vehicles, deleting, onDelete} = props;
    var grid = generateGrid(vehicles, deleting, onDelete);
    return (
        <div>
            <h3>Your vehicles</h3>
            <Grid stretched columns={props.vehicles && props.vehicles.length > 0 ? 2 : 1 }>
                {grid.map((row, i) =>
                    <Grid.Row stretched key={i}>
                        {row.map((column, j) => 
                            <Grid.Column stretched key={column.key}>
                                {column.component}
                            </Grid.Column> 
                        )}
                    </Grid.Row>
                )}
            </Grid>
        </div>
    )
};

// TODO: How to use 'key' prop on iterated items given we're not using a straightforward list type rendering structure????
const generateGrid = (vehicles, vehicleIdBeingDeleted, onDelete) => {
    var grid = []
    var currentRowIndex = -1;

    // TODO: IF NO VEHICLES
    if(!vehicles || vehicles.length === 0) {
        grid.push([]);
        grid[0][0] = {
            "key": "noVehicles",
            "component": <NoVehicles/>
        };
    } else {
        // ELSE
        for (var i = 0; i <= vehicles.length; i++) {
            if(i % 2 == 0) {
                grid.push([]);
                currentRowIndex = currentRowIndex + 1;
            }

            if(i < vehicles.length) {
                var vehicle = vehicles[i];
                grid[currentRowIndex].push({
                    "key": vehicle.id,
                    "component": <Vehicle deleting={vehicle.id === vehicleIdBeingDeleted} onDelete={onDelete} {...vehicle}/>
                });
            } else {
                grid[currentRowIndex].push({
                    "key": "addVehicleLink",
                    "component": <AddVehicleLink/>
                });
            }
        }
    }

    return grid;
}

Vehicles.propTypes = {
    vehicles: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            vrm: PropTypes.string.isRequired
        }).isRequired
    ).isRequired,
    deleting: PropTypes.string,
    onDelete: PropTypes.func.isRequired
};

export default Vehicles