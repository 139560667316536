import React, {Component} from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import CardDetails from '../../components/payment/CardDetails'
import UpdateCardDetails from '../../components/payment/UpdateCardDetails'
import BuUpdateCardDetails from '../../components/payment/BuUpdateCardDetails'

import {
    onCancelUpdatingCardDetails,
    onCardSetupFailed,
    onCardSetupSuccess,
    onFetchCardDetails,
    onSaveCardDetails,
    onSwitchToUpdateCardDetails,
    onBuCardSetupSuccess
} from '../../modules/payment/payment'
import {Elements} from 'react-stripe-elements';

class PaymentDetails extends Component {

    constructor(props) {
        super(props);
    }

    componentWillMount() {
        this.props.onFetchCardDetails()
    }

    handleCardSetupSuccess = (nextAction) => {
        this.props.onBuCardSetupSuccess(nextAction);
    }

    handleCardSetupFailed = (err) => {
        this.props.onCardSetupFailed(err);
    }

    handleSaveCardDetails = () => {
        this.props.onSaveCardDetails();
    }

    handleCancelUpdatingCardDetails = () => {
        this.props.onCancelUpdatingCardDetails();
    }

    handleUpdateCardDetails = () => {
        this.props.onSwitchToUpdateCardDetails();
    }

    render() {
        const { initialEntry, cardDetails, loadingCardDetails, editing, creatingCard, cardCreationError } = this.props;
        if(editing) {
            return (
                <Elements>
                    <BuUpdateCardDetails initial={initialEntry} creating={creatingCard} error={cardCreationError} onSave={this.handleSaveCardDetails} onCancel={ cardDetails ? this.handleCancelUpdatingCardDetails : null} onBuCardSetupSuccess={this.handleCardSetupSuccess} onCardSetupFailed={this.handleCardSetupFailed}/>
                </Elements>
            )
        } else {
            return (
                <CardDetails loading={loadingCardDetails} card={cardDetails} onEdit={this.handleUpdateCardDetails}/>
            );
        }
    }
}

const mapStateToProps = state => (
    {...state.payment}
)

const mapDispatchToProps = dispatch => bindActionCreators({
    onFetchCardDetails,
    onSaveCardDetails,
    onCancelUpdatingCardDetails,
    onCardSetupSuccess,
    onCardSetupFailed,
    onSwitchToUpdateCardDetails,
    onBuCardSetupSuccess
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentDetails)
