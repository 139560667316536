class StripeConfig {
    
    publicApiKey() {
        return window.config.stripe.publicApiKey;
    }

    threeDSecureReturnPath(redirectTo) {
        return window.location.protocol + '//' + window.location.host + `/payment-callback/${redirectTo}`
    }
}

const stripeConfig = new StripeConfig();

export default stripeConfig;