import React, {Component} from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Segment, Divider, Loader, Header, Icon, Message, Container } from 'semantic-ui-react'
import AssignNewPermit from '../../../components/permits/AssignNewPermit'
import AssignedPermits from '../../../components/permits/AssignedPermits'
import { Link } from 'react-router-dom'
import { onFetchPermits, onAddNewPermit, onDeletePermit } from '../../../modules/permits/permits'



class Permits extends Component {

    handleDeletePermit = (permitId) => {
      this.props.onDeletePermit(permitId);
    }

    componentWillMount() {
        this.props.onFetchPermits();
    }

    render() {
        const { permits, fetching, fetchError, onFetchPermits, addingNewPermit, permitCodeError, onAddNewPermit } = this.props;

        if (fetching) {
            return (
                <Container>
                    <AssignNewPermit adding={addingNewPermit} onAssign={onAddNewPermit} permitCodeError={permitCodeError}/>
                    <Loading {... this.props}/>
                </Container>
            );
        } else if (fetchError) {
            return (
                <Container>
                    <AssignNewPermit adding={addingNewPermit} onAssign={onAddNewPermit} permitCodeError={permitCodeError}/>
                    <FetchError onTryAgain={onFetchPermits}/>
                </Container>
            );
        } else {
            if(!permits || permits.length === 0) {
                return (
                    <Container>
                        <AssignNewPermit adding={addingNewPermit} onAssign={onAddNewPermit} permitCodeError={permitCodeError}/>
                        <NoPermits/>
                    </Container>
                );
            } else {
                return (
                    <Container>
                        <AssignNewPermit adding={addingNewPermit} onAssign={onAddNewPermit} permitCodeError={permitCodeError}/>
                        <AssignedPermits permits={permits} onDeletePermit={this.handleDeletePermit}/>
                    </Container>
                );
            }
        }
    }
}

const NoPermits = () => {
    return (
        <Segment disabled secondary textAlign='center'>
            <Header as='h4' icon>
                <Icon name='id card outline' color='grey' />
                    You have no existing permits
            </Header>
        </Segment>
    );
}

const FetchError = (props) => {
    return (
        <Segment disabled secondary textAlign='center'>
            <Header as='h4' icon>
                <Icon name='frown' />
                    Failed to load permit information
                <Header.Subheader>
                    Please&nbsp;<a href='#' onClick={(e) => {e.preventDefault(); props.onTryAgain(); }}>click here</a>&nbsp;to try again.
                </Header.Subheader>
            </Header>
        </Segment>
    );
}

const Loading = (props) => {
  return (
    <Loader active inline='centered' size='small'>Loading permits</Loader>
  )
}

const mapStateToProps = state => ({
      permits: state.permits.permits,
      fetching: state.permits.fetching,
      fetchError: state.permits.fetchError,
      addingNewPermit: state.permits.addingNewPermit,
      permitCodeError: state.permits.permitCodeError
})

const mapDispatchToProps = dispatch => bindActionCreators({
    onFetchPermits,
    onAddNewPermit,
    onDeletePermit
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Permits)
