import React, {Component} from 'react'
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Segment, Grid, Icon, Divider, Header, Table} from 'semantic-ui-react';

const attributeHeaderStyle = {
    'display': 'block',
    'color': 'grey',
    'margin': '0 0 .28rem 0',
    'textTransform': 'none',
    'fontWeight': '700',
    'fontSize': '.9em'
}

const tableStyleHeader = {
    'border': 'none'
}

const tableStyleBody = {
    'border': 'none'
}

const gridStyle = {
    'marginBottom': '-1rem'
}

const gridRowStyle = {
    'marginBottom': '1rem'
}

class ActiveSession extends Component {

    render() {
        const session = this.props;
        return (
            <Segment basic compact>
            <Grid columns={1} verticalAlign='middle'>
                <Grid.Row style={gridRowStyle} stretched>
                    <Grid.Column stretched width={16} textAlign='left'>
                        <Table attached='top' celled style={tableStyleBody} compact basic fixed singleLine unstackable>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell style={tableStyleBody} width='5'>
                                        <Header style={attributeHeaderStyle} sub color='grey'>Date</Header>
                                        <span>{session.date}</span>
                                    </Table.Cell>
                                    <Table.Cell style={tableStyleBody} width='11'>
                                        <Header style={attributeHeaderStyle} sub color='grey'>Car park name</Header>
                                        <span>{session.carParkName}</span>
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                        <Table attached celled style={tableStyleBody} compact basic fixed singleLine unstackable>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell style={tableStyleBody} width='5'>
                                        <Header style={attributeHeaderStyle} sub color='grey'>Time in</Header>
                                        <span>{session.timeIn}</span>
                                    </Table.Cell>
                                    <Table.Cell style={tableStyleBody} width='6'>
                                        <Header style={attributeHeaderStyle} sub color='grey'>Total time</Header>
                                        <span>{session.totalTime}</span>
                                    </Table.Cell>
                                    <Table.Cell style={tableStyleBody} width='5'>
                                        <Header style={attributeHeaderStyle} sub color='grey'>Cost</Header>
                                        <span>{session.estimatedCost.currency}{(session.estimatedCost.amount / 100).toFixed(2)}</span>                          
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                        <Table attached celled style={tableStyleBody} compact basic fixed singleLine unstackable>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell style={tableStyleBody} width='5'>
                                        <Header style={attributeHeaderStyle} sub color='grey'>Vehicle</Header>
                                        <span>{session.vrm}</span>
                                    </Table.Cell>
                                    <Table.Cell style={tableStyleBody} width='11'>
                                        <Header style={attributeHeaderStyle} sub color='grey'>Payment account</Header>
                                        <span>{session.paymentAccount}</span>
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            </Segment>
        );
    }
}

ActiveSession.propTypes = {
    id: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    carParkName: PropTypes.string.isRequired,
    vrm: PropTypes.string.isRequired,
    paymentAccount: PropTypes.string.isRequired,
    estimatedCost: PropTypes.shape({
        currency: PropTypes.string.isRequired,
        amount: PropTypes.number.isRequired
    }).isRequired,
    timeIn: PropTypes.string.isRequired,
    totalTime: PropTypes.string.isRequired,
    isConflicted: PropTypes.bool
};

export default ActiveSession