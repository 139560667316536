import React, {Component} from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Segment, Divider, Loader, Header, Icon, Message } from 'semantic-ui-react'
import { Redirect } from 'react-router'

class Welcome extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { initialising, initialised, initialisationError } = this.props;
        return (
            <div>
                <div className="confirmation-hero">
                    <div className="w-container">
                            <div className="confirmation-hero-content">
                                <div className="confirmation-tick"></div>
                                <h1 className="heading-2">Your vehicle is registered. <br></br>Payment will be taken after you drive out of the car park.</h1>
                                

                            </div>
                    </div>
                </div>
                <div className="section section-light-grey">
                    <div className="w-container">
                        <div className="form-container">
                        <h2 className="heading-2">Please do not make alternative payment</h2>
                        <p>We'll charge you automatically after you drive out of the car park. If you make alternative payment, you may end up paying twice.</p>
                            
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="w-container">
                        <div className="form-container">
                            <h2 className="heading-2">What happens next?</h2>
                            <ul>
                                <li>
                                    <p>We'll send an email confirmation. If you don't receive it, your vehicle is still covered by Hozah Autopay.</p>
                                </li>
                                <li>
                                    <p>After you drive out of the car park, we'll charge you for your session automatically (usually within 10 days).</p>
                                </li>
                                <li>
                                    <p>When you next visit any Autopay-enabled car park, we'll take accurate payment automatically each time.</p>
                                </li>
                                <li>
                                    <p>While you have a valid account with active payment details, registered vehicles will be covered for any PCNs at Autopay car parks.</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="section section-light-grey">
                    <div className="w-container">
                        <div className="form-container">
                            <h2 className="heading-2">Your Autopay account</h2>
                            <p>Now you are registered, you can use your Hozah Autopay account to:</p>
                            <ul>
                                <li>
                                    <p>Add or remove vehicles</p>
                                </li>
                                <li>
                                    <p>Change your payment details</p>
                                </li>
                                <li>
                                    <p>View previous parking invoices</p>
                                </li>
                                <li>
                                    <p>Add discount codes</p>
                                </li>
                            </ul>
                            <div>
                                <a href="/dashboard" id="go-to-dashboard" className="btn btn-secondary w-button centrebutton">Go to your Account</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Welcome;