import React, {Component} from 'react'
import { Segment, Grid, Container } from 'semantic-ui-react'
import PermitForm from '../../components/auth/PermitForm'
import SignupForm from '../../components/auth/BUSignupForm'
import { signup, LOGIN_SUCCESS, setLoginCallback } from '../../modules/auth'
import { SubmissionError } from 'redux-form'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { loginWithGoogle, loginWithFacebook } from '../../modules/auth'
import { push } from 'react-router-redux'
import { bindActionCreators } from 'redux'
import queryString from 'query-string'
import { onAddNewPermit } from '../../modules/permits/permits'
import * as firebase from "firebase";
import Cookies from 'universal-cookie';


const onSignupWithFacebook = () => {
  return dispatch => {
    dispatch(push('/login'));
    loginWithFacebook();
  }
}

const doSignup = (values) => { return dispatch => {
    if (values.creweConsent === "yes") {
        setLoginCallback( () => dispatch(onAddNewPermit('CREWE1')) )
    }
    return signup(values.emailAddress, values.password, values.firstName, values.lastName)
        .catch(err => {
            // TODO: Check for specific error code type (e.g. email not verified) and show more helpful error message
            throw new SubmissionError({
                _error: err
            })
        })
    }
}


const cookies = new Cookies();


class BUSignup extends Component {

    constructor(props) {
        super(props);
        const queryParameters = queryString.parse(window.location.search);
        const crewe = (queryParameters.promo === 'crewe')
        this.state = {
            hasCrewePromo: crewe,
            permitPaymentBand: cookies.get('selectedPermit')
        };
    }

    render() {

         console.log("Onload Cookie:", this.state.permitPaymentBand)


        return (
          <div>
            <Container className="bu-container">
            
            <div className="bu-header"> 
            <h2>Sign up</h2>
            </div>
            <Grid stackable textAlign='left'>
            <Grid.Row columns={2} className="content-container">
            <Grid.Column width={11}>

              {/* if not set load this one */}
        {!this.state.permitPaymentBand ? <><a id="w-node-6f2801999db4-7c09cd64" href="bournemouth-university/signup"
                    className="progress-block-bu progress-block-active-bu w-inline-block">
                    <div className="progress-number-bu">1</div>
                    <div className="progress-title-bu">Choose your Discounted Parking Session Rate</div>
                </a>  <PermitForm />
 </> : <a id="w-node-6f2801999db4-7c09cd64" href="bournemouth-university/signup"
                    className="progress-block-bu progress-block-complete-bu w-inline-block">
                    <div className="progress-number-bu">1</div>
                    <div className="progress-title-bu">Choose your Discounted Parking Session Rate</div>
                </a> }
 <div className="steps-divider"></div>
                {/* if cookie set load this one */}
        {this.state.permitPaymentBand ?
                <><a id="w-node-6f2801999db4-7c09cd64" href="bournemouth-university/signup"
                    className="progress-block-bu progress-block-active-bu w-inline-block">
                    <div className="progress-number-bu">2</div>
                    <div className="progress-title-bu">Tell us about you</div>
                </a> <SignupForm
              onSubmit={this.props.doSignup}
              onFacebookSignup={this.props.onSignupWithFacebook}
              hasCrewePromo={this.state.hasCrewePromo}
              redirectTo={"/bournemouth-university/dashboard"}
        /> </> : <a id="w-node-6f2801999db4-7c09cd64" href="bournemouth-university/signup"
        className="progress-block-bu progress-block-bu w-inline-block">
        <div className="progress-number-bu">2</div>
        <div className="progress-title-bu">Tell us about you</div>
    </a> }
                <div className="steps-divider"></div>
                <a id="w-node-6f2801999db9-7c09cd64" href="bournemouth-university/signup"
                    className="progress-block-bu w-inline-block">
                    <div className="progress-number-bu">3</div>
                    <div className="progress-title-bu">Find your vehicle</div>
                </a> <div className="steps-divider"></div>
                <a id="w-node-6f2801999dbe-7c09cd64" href="bournemouth-university/signup"
                    className="progress-block-bu w-inline-block">
                    <div className="progress-number-bu">4</div>
                    <div className="progress-title-bu">Enter payment details</div>
                </a>        
              </Grid.Column>
              <Grid.Column width={5}>
              <div className="section section-small">
                <div className="w-container">
                    <div className="icon-list-bu">
                    <p className="icon-description"><strong>Secure payment by</strong></p>
                    <div><img src="images/payment-icons.png" />
                        </div>
                    </div>
                </div>
            </div>


              <div className="section section-small">
                <div className="w-container">
                    <div className="icon-list-new">
                    <p className="icon-description"><strong>Purpose of the pilot</strong></p>
                        <div className="icon-list-item-bu"><img src="images/tick-logo.png" width="64" alt="" className="icon" />
                            <p className="bu-fonts">Test automated daily parking charging</p>
                        </div>
                        <div className="icon-list-item-bu"><img src="images/tick-logo.png" width="64" alt="" className="icon" />
                            <p className="bu-fonts">Test a frequency based charging solution</p>
                        </div>
                        <div className="icon-list-item-bu"><img src="images/tick-logo.png" width="64" alt="" className="icon" />
                            <p className="bu-fonts padding">Test user experience</p>
                        </div>
                        <div className="icon-list-item-bu"><img src="images/tick-logo.png" width="64" alt="" className="icon" />
                            <p className="bu-fonts">Test integration with BU car parking infrastructure</p>
                        </div>
                    </div>
                </div>
            </div>
        </Grid.Column>
    </Grid.Row>
    </Grid>
    </Container>

    </div>
        );
    }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
})

const mapDispatchToProps = dispatch => bindActionCreators({
  onSignupWithFacebook,
  doSignup
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BUSignup)