import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import auth from './auth';
import dashboard from './dashboard';
import addVehicle from './vehicles/addVehicle';
import payment from './payment/payment';
import activeSession from './activeSessions/activeSession';
import transactionHistorySummary from './transactionHistory/transactionHistorySummary';
import permits from './permits/permits';
import fullTransactionHistory from './transactionHistory/fullTransactionHistory';
import { reducer as formReducer } from 'redux-form';

export default combineReducers({
  router: routerReducer,
  dashboard,
  addVehicle,
  payment,
  activeSession,
  transactionHistorySummary,
  fullTransactionHistory,
  permits,
  auth,
  form: formReducer
})
