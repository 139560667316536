export function get(obj, key) {
  return key.split('.').reduce((nestedObject, key) => {
    if(nestedObject && key in nestedObject) {
      return nestedObject[key];
    }
    return undefined;
  }, obj);
}

export function validatePassword(password) {
   if(!(/^.{8,}$/u.test(password))) {
     return 'Your password must be a minimum of eight characters';
   } // The password standard used for reference: https://nvlpubs.nist.gov/nistpubs/SpecialPublications/NIST.SP.800-63b.pdf page 67
   return null;
}