import React, {Component} from 'react'
import PropTypes from 'prop-types'
import { Segment, Header, List, Icon } from 'semantic-ui-react';

const NotFound = () => (
    <Segment disabled secondary textAlign='center'>
        <Header as='h4' icon>
            <Icon name='frown' />
                We couldn't find details for that number plate
            <Header.Subheader>
                Have you typed it correctly?
            </Header.Subheader>
        </Header>
    </Segment>
)

const LookupError = (props) => (
    <Segment disabled secondary textAlign='center'>
        <Header as='h4' icon>
            <Icon name='frown' />
                We had a problem looking up your number plate
            <Header.Subheader>
                Please try again
            </Header.Subheader>
        </Header>
    </Segment>
)

class VehicleDetails extends Component {

    render() {
        const { data, notFound, error } = this.props;
        
        if(notFound) {
            return <NotFound/>
        } else if(error) {
            return <LookupError/>
        } else {
            return (
                <Segment class={"form"} secondary>
                    <Header as='h3' dividing>
                        Vehicle information
                    </Header>
                    <List>
                        <List.Item><strong>Make:</strong> {data.make}</List.Item>
                        <List.Item><strong>Colour:</strong> {data.colour}</List.Item>
                        <List.Item><strong>Fuel type:</strong> {data.fuelType}</List.Item>
                        <List.Item><strong>Year:</strong> {data.yearOfManufacture}</List.Item>
                    </List>
                </Segment>
            );
        }
    }
}

VehicleDetails.propTypes = {
    data: PropTypes.shape({
        make: PropTypes.string.isRequired,
        colour: PropTypes.string.isRequired,
        fuelType: PropTypes.string.isRequired,
        yearOfManufacture: PropTypes.number.isRequired
    }),
    notFound: PropTypes.bool,
    error: PropTypes.string
};

export default VehicleDetails;