import React, {Component} from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import { Segment, Input, Form, Button, Message, Label, Icon, Container, Grid } from 'semantic-ui-react'
import { get, validatePassword } from '../../modules/utils'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { loginWithGoogle, loginWithFacebook } from '../../modules/auth'
import { Redirect } from 'react-router-dom'
import queryString from 'query-string'
import { bindActionCreators } from 'redux'
import {onAddNewPermit} from "../../modules/permits/permits";


const validate = (values, props) => {
    const errors = {}
    if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(values.emailAddress)) {
        errors.emailAddress = 'You must enter a valid email address';
    }

    if (!(values.emailAddress === values.confirmEmailAddress)) {
        errors.confirmEmailAddress = 'You must confirm your email address'
    }

    const passwordValidationError = validatePassword(values.password);

    const queryParameters = queryString.parse(window.location.search);
    const crewe = (queryParameters.promo === 'crewe')

    if (props.hasCrewePromo) {
        if (values.creweConsent == null) {
            errors.creweConsent = 'Give your marketing preference'
        }
    }

    if (passwordValidationError) {
        errors.password = passwordValidationError;
    }
    if (!values.firstName) {
        errors.firstName = 'You must enter your first name';
    }
    if (!values.lastName) {
        errors.lastName = 'You must enter your last name';
    } 
    return errors;
}

const FormField = ({input, label, type, descriptionText, descriptionClass, meta: { touched, error, warning }}) => (
    <Form.Field {... touched && error ? {error: true} : {}}>
        <input {...input} placeholder={label} type={type}/> {descriptionText ? <div className={descriptionClass}>{descriptionText}</div> : ""}
      {touched && error ? <Label basic color='red' pointing>{error}</Label>: null}
    </Form.Field>
)

const FormError = (error) => {
    if(error) {
        return (<Message {... error ? {error: true} : {}} header='Sign up failed' content={<span>{error}</span>}/>);
    } else {
        return null;
    }
}

class SignupFormComponent extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            hasErrors: false,
            submitted: false,
            isCrewe: props.hasCrewePromo
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.invalid) {
            this.setState({...this.state, hasErrors: true});
        } else {
            this.setState({...this.state, hasErrors: false});
        }
    }

    render() {
        const { emailAddressValue, error, handleSubmit, pristine, reset, submitting, submitSucceeded, redirectTo } = this.props
        const { hasErrors, submitted, isCrewe } = this.state
        if(submitSucceeded) {
            return (
                <Redirect to={redirectTo} />
            );
        } else {
            return (    

                    <>
                        <Form className="form-new" {...hasErrors ? {error: true} : {error: false}} onSubmit={handleSubmit}>
                            {error ? FormError(error): null}
                            <Grid columns='equal' stackable textAlign='left'>
                            <Grid.Row columns={2}>                            
                            <Grid.Column>
                            <label>First name*</label>
                            <Field name="firstName" type="text" label="e.g. John" component={FormField}/>
                            </Grid.Column>
                            <Grid.Column>
                            <label>Last name*</label>
                            <Field name="lastName" type="text" label="e.g. Smith" component={FormField}/>
                            </Grid.Column>
                            <Grid.Column>
                            <label>Email address*</label>
                            <Field name="emailAddress" type="text" label="e.g. john@emailaddress.com" component={FormField}/>
                            </Grid.Column>
                            <Grid.Column>
                            <label>Confirm email address*</label>
                            <Field name="confirmEmailAddress" type="text" label="e.g. john@emailaddress.com" component={FormField}/>
                            </Grid.Column>
                            <Grid.Column width={8}>
                            <label>Password*</label>
                            <Field name="password" type="password" label="Choose a secure password" component={FormField}/>
                            </Grid.Column>

                            </Grid.Row>
                            </Grid>

                            {isCrewe ? <p> In addition to providing an automatic parking system we can also provide you with optional extras, such as a free parking session and discounts for the shops in The Market. Do you want to receive these extra benefits? </p> : null}
                            {isCrewe ? <label> <Field name="creweConsent" component={FormField} type="radio" value="yes" descriptionText="YES, please give me a free parking session and email me shopping discounts" descriptionClass="marketingdescription"/>  </label> : null }
                            {isCrewe ? <label> <Field name="creweConsent" component={FormField} type="radio" value="no" descriptionText="NO, I'll just use the automatic Hozah system to pay for parking" descriptionClass="marketingdescription"/>  </label> : null }
                            <p>By continuing, you agree to our <a href="https://hozah.com/terms/">Terms and Conditions</a> and <a href="https://hozah.com/privacy-and-cookies/">Privacy policy</a></p>
                            <Button id="signupForm-new" className="bu-permit-button" color='green' size='massive' type="submit" disabled={(hasErrors && !error) || pristine || submitting} loading={submitting}>Continue to vehicle details</Button>
                            

                        </Form>
                        </>
                      
            );
        }
    }
}

var SignupForm = reduxForm({
  form: 'signupForm',
  validate
})(SignupFormComponent)

const selector = formValueSelector('signupForm')

SignupForm = connect(
  state => {
    const queryParameters = queryString.parse(window.location.search);
    const defaultEmail = queryParameters.email ? queryParameters.email : ""
    return {
       initialValues: {
          emailAddress: defaultEmail
       }
    }
  }
)(SignupForm)

export default SignupForm