import React, {Component} from 'react'
import PropTypes from 'prop-types'
import { Button, Card } from 'semantic-ui-react'

class LookupVehicle extends Component {
  
  handleLookupClick = () => {
    const vrm = this._vrm.value;
    this.props.onLookup(vrm);
  }

  render() {
    const { fetching, disabled } = this.props;
    const className = "ui action input fluid" + (disabled ? " disabled" : "")
    return (
        <div className={className}>
            <input id="input-VRM-new" className="bu-vehicle-lookup-input" type="text" ref={input => this._vrm = input} placeholder="e.g. AB19 XYZ"/>
            <Button id="lookup-vrm-button-new" className="bu-vehicle-lookup-button" onClick={this.handleLookupClick} loading={fetching} disabled={fetching || disabled ? true : false}>Look up</Button>
        </div>

    );
  }
}

LookupVehicle.propTypes = {
  onLookup: PropTypes.func.isRequired,
  fetching: PropTypes.bool,
  disabled: PropTypes.bool
};

export default LookupVehicle