import React, {Component} from 'react'
import { connect } from 'react-redux'
import {Button, Dropdown, Header, Loader, Menu, Segment, Container, Grid} from 'semantic-ui-react'
import {Route, Switch, withRouter} from 'react-router-dom'
import PropTypes from 'prop-types'




class BuHeader extends React.Component {

  static contextTypes = {
    router: PropTypes.shape({
      history: PropTypes.shape({
        push: PropTypes.func.isRequired,
        replace: PropTypes.func.isRequired
      }).isRequired,
      staticContext: PropTypes.object
    }).isRequired
  }

  render() {

    const buUnauthenticatedMenu = (
        <div className="BU-logos">
        <p className="trial-infomation">Trial Infomation</p>
        </div>
    );

    const { history } = this.context.router;

    const homeLink = () => history.push('/bournemouth-university');
    const BuSignupLink = () => history.push('/bournemouth-university/signup');
    const BuDashboardLink = () => history.push('/bournemouth-university/dashboard');
    const loginLink = () => history.push('/login');

    const unauthenticatedMenu = (
      <Menu.Menu position="right">
        <Menu.Item className="log-in-button" onClick={loginLink}>Log in</Menu.Item>
        <Menu.Item className="sign-up-button" fitted>
          <Button color='green' onClick={BuSignupLink}>Sign up</Button>
        </Menu.Item>
      </Menu.Menu>
    );

    const authenticatedMenu = () => (
      <Menu.Menu position="right">
        <Menu.Item className="log-out-button" name="logout" onClick={this.props.logout}>Logout</Menu.Item>
        <Dropdown item icon="content">
            <Dropdown.Menu>
              <Dropdown.Item onClick={BuDashboardLink}>My dashboard</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
      </Menu.Menu>
    );

        return (
          
            <>
            <Container className="bu-container bu-menu">
            <Grid stackable>
            <Grid.Row columns={2} className="content-container">
            <Grid.Column >
            <a header onClick={homeLink} style={{paddingRight:"unset"}}>
            <picture >
            <img className="BU-logos" src="images/bu-poweredby-hozah-image-1.png"/>
            </picture>
            </a>
            </Grid.Column>
      
            <Grid.Column>
      
      
            {this.props.isAuthenticated ? authenticatedMenu() : buUnauthenticatedMenu}
      
          </Grid.Column>
          </Grid.Row>
          </Grid>
          </Container>
      
            </>
            );
      };
    }
export default connect()(BuHeader)