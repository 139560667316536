import React, {Component} from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Step, Icon, Grid, Segment, Container } from 'semantic-ui-react'
import AddVehicle from '../buAddVehicle'
import PaymentDetails from './PaymentDetails'
import addVehicle from '../../modules/vehicles/addVehicle'

class DriverSettingsWizard extends Component {

    render() {

        const { hasPaymentCard, vehicles } = this.props;
        const requiresVehicle = vehicles.length === 0;
        const requiresPaymentCard = !hasPaymentCard;

        if(requiresVehicle || requiresPaymentCard) {
            return (
        <div>
        <Container className="bu-container">
            <div className="bu-header"> 
            <h2>Sign Up</h2>
            </div>
                <Grid stackable textAlign='left'>
                    <Grid.Row columns={2} className="content-container">
                    <Grid.Column width={10}>
                    <div>
                    <a id="w-node-6f2801999db4-7c09cd64" href="#"
                    className="progress-block-bu progress-block-complete-bu w-inline-block">
                    <div className="progress-number-bu">1</div>
                    <div className="progress-title-bu">Choose your discounted parking rate</div>
                </a> <div className="steps-divider"></div>

                                    <a id="w-node-6f2801999db4-7c09cd64" href="/bournemouth-university/dashboard"
                                       className="progress-block-bu progress-block-complete-bu w-inline-block">
                                        <div className="progress-number-bu">1</div>
                                        <div className="progress-title-bu">Tell us about you</div>
                                    </a> <div className="steps-divider"></div>

                                    {requiresVehicle ?
                                        <a id="w-node-6f2801999db9-7c09cd64" href="/bournemouth-university/dashboard"
                                           className="progress-block-bu progress-block-active-bu w-inline-block w--current">
                                            <div className="progress-number-bu">2</div>
                                            <div className="progress-title-bu">Find your vehicle</div>
                                        </a>
                                        :
                                        <a id="w-node-6f2801999db9-7c09cd64" href="/bournemouth-university/dashboard"
                                           className="progress-block-bu progress-block-complete-bu w-inline-block w--current">
                                            <div className="progress-number-bu">2</div>
                                            <div className="progress-title-bu">Find your vehicle</div>
                                        </a> }
                                        {requiresVehicle ? <AddVehicle hideBackLink={true} /> : null }
                                        <div className="steps-divider"></div>
                                    {!requiresVehicle && requiresPaymentCard ?
                                        <a id="w-node-6f2801999dbe-7c09cd64" href="/bournemouth-university/dashboard"
                                           className="progress-block-bu progress-block-active-bu w-inline-block">
                                            <div className="progress-number-bu">3</div>
                                            <div className="progress-title-bu">Enter payment details</div>
                                        </a> :
                                        <a id="w-node-6f2801999dbe-7c09cd64" href="/bournemouth-university/dashboard"
                                           className="progress-block-bu w-inline-block">
                                            <div className="progress-number-bu">3</div>
                                            <div className="progress-title-bu">Enter payment details</div>
                                    </a> }
                                    {requiresVehicle ? null : <PaymentDetails initialEntry="Yes" key={1}/>}

                                </div>

                    <br/>
                    <br/>
                    </Grid.Column>

                    <Grid.Column width={6}>
              <div className="section section-small">
                <div className="w-container">
                    <div className="icon-list-new">
                    <p className="icon-description"><strong>Secure payment by</strong></p>
                    <div><img src="images/payment-icons.png" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="section section-small">
                <div className="w-container">
                    <div className="icon-list-new">
                    <p className="icon-description"><strong>Purpose of the pilot</strong></p>
                        <div className="icon-list-item-bu"><img src="images/tick-logo.png" width="64" alt="" className="icon" />
                            <p className="bu-fonts">Test automated daily parking charging</p>
                        </div>
                        <div className="icon-list-item-bu"><img src="images/tick-logo.png" width="64" alt="" className="icon" />
                            <p className="bu-fonts">Test a frequency based charging solution</p>
                        </div>
                        <div className="icon-list-item-bu"><img src="images/tick-logo.png" width="64" alt="" className="icon" />
                            <p className="bu-fonts padding">Test user experience</p>
                        </div>
                        <div className="icon-list-item-bu"><img src="images/tick-logo.png" width="64" alt="" className="icon" />
                            <p className="bu-fonts">Test integration with BU car parking infrastructure</p>
                        </div>
                    </div>
                </div>
            </div>
            </Grid.Column>
            </Grid.Row>
            </Grid>
            </Container>
                </div>
            )
        } else {
            return this.props.children;
        };
    }
}

const mapStateToProps = state => ({
    hasPaymentCard: state.dashboard.hasPaymentCard,
    vehicles: state.dashboard.vehicles
})

const mapDispatchToProps = dispatch => bindActionCreators({
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DriverSettingsWizard)
