import { CALL_API } from 'redux-api-middleware';

export const FETCH_PERMITS = 'permits/FETCH_PERMITS'
export const FETCHED_PERMITS = 'permits/FETCHED_PERMITS'
export const FAILED_TO_FETCH_PERMITS = 'permits/FAILED_TO_FETCH_PERMITS'

export const ASSIGN_PERMIT= 'permits/ASSIGN_PERMIT'
export const ASSIGNED_PERMIT = 'permits/ASSIGNED_PERMIT'
export const FAILED_TO_ASSIGN_PERMIT = 'permits/FAILED_TO_ASSIGN_PERMIT'

export const UNASSIGN_PERMIT= 'permits/UNASSIGN_PERMIT'
export const UNASSIGNED_PERMIT = 'permits/UNASSIGNED_PERMIT'
export const FAILED_TO_UNASSIGN_PERMIT = 'permits/FAILED_TO_UNASSIGN_PERMIT'

export const onFetchPermits = () => {
    return dispatch => {
        dispatch({
            [CALL_API]: {
                endpoint: '/permits',
                method: 'GET',
                types: [FETCH_PERMITS, FETCHED_PERMITS, FAILED_TO_FETCH_PERMITS]
            }
        });
    }
}

export const onAddNewPermit = (permitCode) => {
    return dispatch => {
        dispatch({
            [CALL_API]: {
                endpoint: '/permits',
                method: 'POST',
                body: JSON.stringify({ secret_code: permitCode }),
                types: [
                  ASSIGN_PERMIT,
                  ASSIGNED_PERMIT,
                  {
                    type: FAILED_TO_ASSIGN_PERMIT,
                    meta: (action, state, res) => {
                      if (res.status == 409) {
                        return {
                          alreadyAssigned: true
                        };
                      } else {
                        return {};
                      }
                    }
                  }]
            }
        });
    }
}

export const onDeletePermit = (permitId) => {
   return dispatch => {
       dispatch({
           [CALL_API]: {
               endpoint: `/permits/${permitId}`,
               method: 'DELETE',
               types: [
                 UNASSIGN_PERMIT,
                 {
                     type: UNASSIGNED_PERMIT,
                     meta: (action, state, res) => {
                        dispatch({
                            [CALL_API]: {
                                endpoint: '/permits',
                                method: 'GET',
                                types: [FETCH_PERMITS, FETCHED_PERMITS, FAILED_TO_FETCH_PERMITS]
                            }
                        });
                        return {
                            id: permitId
                        }
                     }
                 },
                 FAILED_TO_UNASSIGN_PERMIT
               ]
           }
       });
   }
}

const initialState = {
  permits: [],
  fetching: true,
  fetchError: null,
  addingNewPermit: false,
  permitCodeError: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PERMITS:
      return {
        ...state,
        permits: [],
        fetching: true,
        fetchError: null,
        permitCodeError: null
      };
    case FETCHED_PERMITS:
      return {
        ...state,
        permits: action.payload.permits,
        fetching: false,
        fetchError: null
      };
    case FAILED_TO_FETCH_PERMITS:
      return {
        ...state,
        fetching: false,
        fetchError: action.payload.statusText
      };
    case ASSIGN_PERMIT:
      return {
        ...state,
        addingNewPermit: true,
        permitCodeError: null
      };
    case ASSIGNED_PERMIT:
      return {
        ...state,
        permits: state.permits.concat(action.payload),
        addingNewPermit: false,
        permitCodeError: null
      };
    case FAILED_TO_ASSIGN_PERMIT:
      return {
        ...state,
        addingNewPermit: false,
        permitCodeError: action.meta.alreadyAssigned ? "Permit is already assigned to your account" : "Permit code not recognised"
      };

    default:
      return state;
  }
}