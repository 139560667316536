import { CALL_API } from 'redux-api-middleware';

export const FETCH_TRANSACTION_HISTORY_SUMMARY = 'transactionHistorySummary/FETCH_TRANSACTION_SUMMARY'
export const FETCHED_TRANSACTION_HISTORY_SUMMARY = 'transactionHistorySummary/FETCHED_TRANSACTION_SUMMARY'
export const FAILED_TO_FETCH_TRANSACTION_HISTORY_SUMMARY = 'transactionHistorySummary/FAILED_TO_FETCH_TRANSACTION_HISTORY_SUMMARY'

export const onFetchTransactionHistorySummary = () => {
    return dispatch => {
        dispatch({
            [CALL_API]: {
                endpoint: '/transactions/summary',
                method: 'GET',
                types: [FETCH_TRANSACTION_HISTORY_SUMMARY, FETCHED_TRANSACTION_HISTORY_SUMMARY, FAILED_TO_FETCH_TRANSACTION_HISTORY_SUMMARY]
            }
        });
    }
}

const initialState = {
  transactions: [],
  expandedTransactionId: null,
  fetching: true,
  fetchError: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TRANSACTION_HISTORY_SUMMARY:
      return {
        ...state,
        transactions: [],
        expandedTransactionId: null,
        fetching: true,
        fetchError: null
      };
    case FETCHED_TRANSACTION_HISTORY_SUMMARY:
      return {
        ...state,
        transactions: action.payload,
        expandedTransactionId: null,
        fetching: false,
        fetchError: null
      };
    case FAILED_TO_FETCH_TRANSACTION_HISTORY_SUMMARY:
      return {
        ...state,
        fetching: false,
        fetchError: action.payload.statusText
      };
    default:
      return state;
  }
}